import React, { useEffect, useState } from 'react'
import { UserRoleCell } from '../../../Raw/raw'

import UserRoleTable from './UserRoleTable'
import {  useFetchRoleListMutation, useFetchRoleSearchMutation } from '../../../store/api/RoleApi';
import AddRoleModal from './AddRoleModal';

import EditPermissionModal from './EditPermissionModal';
import Permission from './Permission';
import SearchToggleInput from '../SearchToggleInput';
function Roles() {


 

    const [isEdit, setisEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [permissionName, setPermissionName] = useState([]);
    const [userName, setUserName] = useState("");
    const [roleId, setRoleId] = useState(null)
    const [totalCount, setTotalCount] = useState(0)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [nodata, setNodata] = useState(false)
    const [tableData, setTableData]= useState([])
    const [refreshData, setRefreshData] = useState(false)
    const [fetchList, { isLoading: isFetching }] = useFetchRoleListMutation();
    const [fetchSearchList, { isLoading: isFetchingSearch }] = useFetchRoleSearchMutation();
    
    useEffect(() => {
        const data = {
            per_page: rowsPerPage,
            page,
            searchQuery
        }
        const fetchFunction = searchQuery ? fetchSearchList : fetchList; // Use fetchList for regular fetching, fetchSearchList for searching
        fetchFunction(data).then((result) => {
           
            const updatedData = result?.data?.data;

            if (updatedData?.length === 0) {
                setNodata(true)
            }
            setTableData(updatedData)

            setTotalCount(result?.data?.data?.total)
        }).catch((error) => {
            console.log(error)
        })


        // eslint-disable-next-line
    }, [page, rowsPerPage, searchQuery, refreshData]);
    if (isEdit) {
        return <Permission />
    }
    const handleEditRole = (roleData) => {

        setRoleId(roleData.id)
        setUserName(roleData.name)
        setPermissionName(roleData?.permissions?.map((obj) => obj.name) || [])
        setOpen(true)
    }
    return (
        <>

            <div className="flex justify-between">
                <h2 className="text-[42px] font-[700] uppercase">USER ROLES</h2>

                <div className="flex gap-x-4 h-12">


                    <AddRoleModal
                        permissionName={permissionName}
                        setPermissionName={setPermissionName}
                        userName={userName}
                        setUserName={setUserName}
                        open={open}
                        setOpen={setOpen}
                        roleId={roleId}
                        setRoleId={setRoleId}
                        setRefreshData={setRefreshData}
                    />

                    <EditPermissionModal setisEdit={setisEdit} />

                    <SearchToggleInput
                        setSearchQuery={setSearchQuery}
                    />

                </div>
            </div>


            <UserRoleTable
                data={tableData}
                headCells={UserRoleCell}
                isLoading={isFetching || isFetchingSearch}
                handleEditRole={handleEditRole}
                totalCount={totalCount}
                page={page} setPage={setPage}
                rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage}
                nodata={nodata}
            />
        </>
    )
}

export default Roles