import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Link from '@mui/material/Link';
import { BackIcon } from '../../../Raw/raw';

function handleClick(event) {
  event.preventDefault();
  // console.info('You clicked a breadcrumb.');
}

export default function BasicBreadcrumbs({setTabName , setBreadCrumbsBtn}) {
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
      
     <h2 style={{color:"#797979", cursor:"pointer", fontWeight:"bolder",fontSize:"x-large"}} color='white'   onClick={()=>setBreadCrumbsBtn(true)} > HOME</h2>
     <BackIcon/>
        <Typography color="text.primary"  style={{ cursor:"pointer", fontWeight:"bolder",fontSize:"x-large"}} className='EditPermissionText'>EDIT PERMISSION</Typography>
      </Breadcrumbs>
    </div>
  );
}

