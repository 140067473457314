import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// const token=localStorage.getItem("xperi_userToken");
const UserApi = createApi({
  reducerPath: "UserApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/api`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      const token = state.users.token
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchList: builder.mutation({
        query: (data) => {
          return {
            url: `/users/all?page=${data?.page+1}`,
            body:{per_page:data?.per_page},
            method: "POST",
          };
        },
        providesTags:["getUser"]
      }),
      fetchSearchList: builder.mutation({
        query: (data) => {
          return {
            url: `/user/search?page=${data?.page+1}`,
            body:{
              per_page:data?.per_page,
              keyword:data?.searchQuery
            },
            method: "POST",
          };
        },
        providesTags:["getUser"]
      }),
     CreateUser: builder.mutation({
        query: (data) => {
          return {
            url: "/user/create",
            body:data,
            method: "POST",
          };
        },
        invalidatesTags:["getUser"]
      }),
      RemoveUser: builder.mutation({
        query: (id) => {
          return {
            url: "user/delete",
            body:{id:id  } , 
            method: "POST",
          };
        },
        invalidatesTags:["getUser"]
      }),
 
      GetLanguage: builder.query({
        query: (data) => {
          return {
            url: "languages",
            
            method: "GET",
          };
        },
        invalidatesTags:["getUser"]
      }),
      
      EditUser: builder.mutation({
        query: (data) => {
          return {
            url: "/user/update",
            body: data,
            method: "POST",
          };
        },
        invalidatesTags:["getUser"]
      }),

    };
  },
});

export const {useFetchListMutation , useCreateUserMutation ,useRemoveUserMutation ,useGetLanguageQuery ,useEditUserMutation ,useFetchSearchListMutation} = UserApi;
export {UserApi}