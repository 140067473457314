import React from 'react'
import logo from '../../../assets/images/xperi-logo-white.svg'
function LoginWrapper({children}) {
  return (
    <div className='login_wrapper px-3 md:px-[100px] py-6 md:py-[10px] xl:py-[60px]'>
   <div className="top_middle"></div>
    <div className="bottom_left"></div>
    <div className="bottom_right"></div>
    <div className='flex h-full'>
    <div className='w-full lg:w-1/2 login_form_wrapper'>
        {children}
    </div>
    <div className='hidden lg:flex flex-1  justify-center items-center'>
    <div className="Login_logo">
      <img src={logo} alt="Xperi Logo" />
      {/* here is logo of xperi */}
    </div>
    </div>
    </div>
    </div>
  )
}

export default LoginWrapper