import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';

export default function TableRowCheckbox({isChecked,data,keyname,updateRowHandler}) {
  // const [checked, setChecked] = React.useState((isChecked===1 || isChecked==='1')?true: false);
  const userData = useSelector((state) => state.users.data)

  const handleChange = (event) => {
    const newChecked = event.target.checked;
    const name = event.target.name;
    if(name==='Review_Complete' || name==='Select_all'){
        const isConfirmed = window.confirm('Are you sure you want to mark review as complete?');
        if (isConfirmed) {
            // If confirmed, update the state and call updateRowHandler
            // setChecked(newChecked);
            const newData = { ...data };
            newData[keyname] = newChecked ? 1 : 0;
            updateRowHandler(newData);
        } else {
            // If not confirmed, revert the checkbox state
            // setChecked(!newChecked);
        }
    }
   else{

      //  setChecked(newChecked);
       // Create a new object to avoid mutating the original data
       const newData = { ...data };
       // Update the key's value based on the checkbox state
       newData[keyname] = newChecked ? 1 : 0;
       
       updateRowHandler(newData)
   }
  };

  return (
    <Checkbox
      checked={data[keyname] ===1}
      disabled={data?.Review_Complete===1 ||userData?.roles[0]?.name!=='QA'}
      onChange={handleChange}
      name={keyname}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
}