import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { CrossIcon } from '../../../Raw/raw';
import {
    useEditUserMutation,
    useGetLanguageQuery
} from '../../../store/api/UserApi';
import BasicSelect from '../SelectDropdown';
import SelectCheckBoxDropdown from '../CheckBoxDropdown';
import { useFetchListQuery } from '../../../store/api/RoleApi';
import { useSnackbar } from '../../../utils/Provider/SnackbarContext';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 472,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: "24px",
    p: 4,
    background: "radial-gradient(129.62% 164.52% at 106.15% 5.08%, rgba(0, 0, 0, 0.08) 11.75%, rgba(98, 38, 167, 0.10) 44.3%, rgba(228, 7, 125, 0.10) 86.9%)",
    backdropFilter: "blur(40px)",
    overflowY: 'auto', // Enable vertical scrolling
    maxHeight: '90vh', // Set maximum height to allow scrolling
};

export default function EditUserModal({ open, onClose, selectedUser, setRefreshData, fullName, setfullName, role, setRole, email, setEmail, language, setlanguage }) {


    const { data: fetchRole } = useFetchListQuery();


    const { data: fetchlanguage } = useGetLanguageQuery();

    const [editUser, { isLoading }] = useEditUserMutation();
    const { showSnackbar } = useSnackbar();



    const handleUser = () => {
        // console.log(value)
        let languageids;
        if (language.includes('Select All')) {


            languageids = fetchlanguage?.data.map((obj) => String(obj.id))


        }
        else {
            languageids = language?.map((name) => {

                return String(fetchlanguage?.data.filter(x => x.name === name)[0].id);
            })

        }
        editUser({ id: selectedUser?.id, user_name: fullName, role, language: languageids, email, status: selectedUser?.status }).unwrap()
            .then((result) => {
                //   console.log(result)
                if (result.status) {
                    onClose()
                    setfullName('')
                    setEmail('');
                    setlanguage([]);
                    setRole('');
                    showSnackbar('success', result.message)
                    setRefreshData((prev) => !prev)
                }
                else {
                    showSnackbar('error', result.message)
                }
            })
            .catch((error) => {
                console.error('Failed to create user:', error);
                if (error?.data?.errors) {
                    const firstErrorKey = Object.keys(error?.data?.errors)[0];
                    const firstErrorMessage = error?.data?.errors[firstErrorKey][0];
                    showSnackbar('error', firstErrorMessage)
                }
                else {
                    showSnackbar('error', error?.data?.message)
                }
            });



    }
    return (
        <div>


            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='flex justify-between items-center cursor-pointer mb-6'><h2 className='text-[32px] font-[700] uppercase'>Edit User</h2>
                        <div onClick={onClose} >
                            <CrossIcon />
                        </div>

                    </div>
                    <div className='flex flex-col gap-y-[20px]'>

                        <div className='flex flex-col gap-y-[16px]'>
                            <label className="text-base   font-bold text-white" htmlFor="email-input">
                                Full Name
                            </label>
                            <input
                                className='form_input w-full px-3 py-4 bg-white bg-opacity-[0.05] rounded-lg text-white'
                                type="text"
                                name="name"
                                id="email-input"
                                value={fullName}
                                onChange={
                                    (e) => setfullName(e.target.value)

                                }

                                autoComplete="off"
                            />
                            <label className="text-base   font-bold text-white" htmlFor="email-input">
                                Email
                            </label>
                            <input
                                className='form_input w-full px-3 py-4 bg-white bg-opacity-[0.05] rounded-lg text-white'
                                type="text"
                                name="email"
                                value={email}
                                onChange={
                                    (e) => setEmail(e.target.value)

                                }
                                id="email-input"

                                autoComplete="off"
                            />


                        </div>

                        <label className="text-base   font-bold text-white" htmlFor="email-input">
                            Role
                        </label>

                        <BasicSelect role={role} setRole={setRole} fetchRole={fetchRole?.data} />
                        <label className="text-base   font-bold text-white" htmlFor="email-input">
                            Language
                        </label>
                        {/* {console.log(fetchlanguage)} */}
                        <div className='w-full'>
                            <SelectCheckBoxDropdown data={fetchlanguage?.data} personName={language} setPersonName={setlanguage} />
                        </div>


                    </div>
                    <div className='mt-[22px] flex justify-between gap-4'>
                        <Button variant="outlined" onClick={onClose}  >Cancel</Button>
                        <Button
                            disabled={isLoading}
                            variant="gradient" onClick={handleUser}>Save</Button>
                    </div>

                </Box>
            </Modal>
        </div>
    );
}