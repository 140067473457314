import * as React from 'react';
import { Button } from '@mui/material';
import { EditIcon } from '../../../Raw/raw';

export default function EditPermissionModal({setisEdit}) {

  return (
    <div className='btnEditPermissionLayout flex ' style={{ height: "48px" }}>
     
        <Button
          className="btnEditPermission whitespace-nowrap border  !min-w-[200px] gap-x-[12px] !px-[24px] !py-[8px] "
          variant='outlined'
           onClick={()=>setisEdit(true)}         
        >
          <div className="EditBtnLayout"> <EditIcon /> </div>
        EDIT PERMISSION  
        </Button>
    </div>
  );
}
