import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { SearchIcon } from '../../../Raw/raw'; // Assuming you have a custom icon component for the close button
// import { useSnackbar } from '../../utils/Provider/SnackbarContext';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';
import { useFetchListLibraryMutation } from '../../../store/api/LibraryApi';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 472,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: "16px",
    p: 4,
    background: "#202020",
    backdropFilter: "blur(40px)",
    overflowY: 'auto', // Enable vertical scrolling
    maxHeight: '90vh', // Set maximum height to allow scrolling
};

function LibraryModal({ isOpen, handleClose, setSelectedFile,  setCurrentFile }) {
    // const { showSnackbar } = useSnackbar();
    const [fetchList] = useFetchListLibraryMutation();
    const [librayData, setLibrayData] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [file, setfile]= useState(null)
    const [fileId, setfileId]= useState(null)
    useEffect(() => {
        fetchList().then((result) => {
            setLibrayData(result?.data?.data)
            setFilteredData(result?.data?.data);
        }).catch((error)=>{
            console.log(error)
        })
    }, [fetchList])

  

    useEffect(() => {
        // Filter library data based on search query
        const filtered = librayData?.filter(file =>
            file.document_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filtered);
    }, [searchQuery, librayData]);


    const handleFileChange = (event) => {
        const selectedFile = event.target.value;
       
        setfile(selectedFile)
    };
    const handleClick = (file) => {
        setfileId(file?.id)
    }
    const handleSelectFile = () => {
        handleClose();
        setCurrentFile(file);
        setSelectedFile(fileId)

    };

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className='flex flex-col justify-center items-center'>
                    <h2 className='text-lg font-bold'>Choose from library</h2>
                    {/* <div className='cursor-pointer' onClick={handleClose}>
                        <CrossIcon />
                    </div> */}
                    <span className='text-[#9D9D9D] text-sm font-normal'>Select one to upload</span>
                    <div className='flex px-6 py-2 border-[#9D9D9D] border-[1px] rounded-lg w-full my-4'>
                        <span>
                            <SearchIcon />
                        </span>
                        <input className='bg-transparent px-1 text-base outline-none' 
                         type="text"
                         name="search"
                         placeholder='Search'
                         value={searchQuery}
                         onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </div>
                <div className='max-h-[50vh] overflow-y-auto'>
                    <FormControl
                        sx={{ width: '100%' }}
                    >

                        {/* <FormLabel id="demo-radio-buttons-group-label">Please Select File</FormLabel> */}
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="radio-buttons-group"
                            onChange={handleFileChange}

                            className='space-y-3'
                        >

                            {
                            filteredData?.length === 0 ? (
                                <div className='text-center'>No file found</div>
                            ) : (

                            filteredData?.map((file) => (
                                <FormControlLabel
                                    key={file.id}
                                    onClick={() => handleClick(file)}
                                    value={file.document_name}
                                    control={<Radio />}
                                    label={
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M11.6668 1.8913V5.33342C11.6668 5.80013 11.6668 6.03348 11.7577 6.21174C11.8376 6.36854 11.965 6.49603 12.1218 6.57592C12.3001 6.66675 12.5335 6.66675 13.0002 6.66675H16.4423M16.6668 8.3236V14.3334C16.6668 15.7335 16.6668 16.4336 16.3943 16.9684C16.1547 17.4388 15.7722 17.8212 15.3018 18.0609C14.767 18.3334 14.067 18.3334 12.6668 18.3334H7.3335C5.93336 18.3334 5.2333 18.3334 4.69852 18.0609C4.22811 17.8212 3.84566 17.4388 3.60598 16.9684C3.3335 16.4336 3.3335 15.7335 3.3335 14.3334V5.66675C3.3335 4.26662 3.3335 3.56655 3.60598 3.03177C3.84566 2.56137 4.22811 2.17892 4.69852 1.93923C5.2333 1.66675 5.93336 1.66675 7.3335 1.66675H10.01C10.6215 1.66675 10.9272 1.66675 11.2149 1.73582C11.47 1.79707 11.7139 1.89808 11.9375 2.03515C12.1898 2.18975 12.406 2.40594 12.8384 2.83832L15.4953 5.49517C15.9276 5.92755 16.1438 6.14374 16.2984 6.39604C16.4355 6.61972 16.5365 6.86358 16.5978 7.11867C16.6668 7.40639 16.6668 7.71213 16.6668 8.3236Z" stroke="#9D9D9D" strokeOpacity="0.6" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <div style={{ wordBreak: 'break-word' }}>{file.document_name}</div>
                                        </div>
                                    }
                                    sx={{
                                        width: '100%',
                                        borderRadius: '12px',
                                        border: '1px solid rgba(234, 236, 240, 0.20)',
                                        background: 'rgba(255, 255, 255, 0.05)',
                                        padding: '16px',
                                        justifyContent: 'space-between'
                                    }}
                                    labelPlacement="start"
                                />
                            ))
                            )
                        }
                        </RadioGroup>
                    </FormControl>
                </div>
                {/* {currentFile && (
                    <div>
                        <p>Selected File: {currentFile}</p>
                    </div>
                )} */}
                <div className='mt-[22px] flex justify-between gap-4'>
                    <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    <Button variant="gradient" onClick={handleSelectFile}>Select</Button>
                </div>
            </Box>
        </Modal>
    );
}

export default LibraryModal;
