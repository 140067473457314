import React, { useEffect, useRef, useState } from 'react'
import { CrossIcon, SearchIcon } from '../../Raw/raw';
import { Button } from '@mui/material';

function SearchToggleInput({ setSearchQuery }) {
    const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);
    const inputRef = useRef(null)

    useEffect(() => {
        if (isSearchInputOpen && inputRef.current) {
            // Focus the input element
            inputRef.current.focus();
        }
    }, [isSearchInputOpen]);
    const handleCloseSearchInput = () => {
        setIsSearchInputOpen(false);
        setSearchQuery('')
        setSearchValue('')
    };
    const handleDelayedSearch = (value) => {
        setSearchValue(value)
        clearTimeout(timeoutId);
        const newTimeoutId = setTimeout(() => {
            setSearchQuery(value);
        }, 500); // Adjust the delay as needed
        setTimeoutId(newTimeoutId); // Update the timeoutId state
    };
    const handleOpen = () => {
        setIsSearchInputOpen(true)
       
    }
    return (
        <>
            {isSearchInputOpen ? (
                <div className="flex items-center px-[24px] py-[8px]  border border-solid rounded-lg border-pink-600">
                    <SearchIcon />
                    <input
                        ref={inputRef}
                        value={searchValue}
                        onChange={(e) => handleDelayedSearch(e.target.value)}
                        type="text" placeholder="Search" className="bg-transparent outline-none w-full px-3" />
                    <span className="cursor-pointer" onClick={handleCloseSearchInput}>
                        <CrossIcon />
                    </span>
                </div>
            )
                :

                <Button className="!px-[24px] !py-[8px] !w-[72px] !border !border-solid !border-pink-600" onClick={handleOpen}>
                    <SearchIcon />
                </Button>
            }
        </>
    )
}

export default SearchToggleInput