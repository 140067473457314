import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import { setupListeners } from "@reduxjs/toolkit/query";
import userSlice from "./slices/UserSlice";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import { ResetPasswordApi } from "./api/ResetPassword";
import { LibraryApi } from "./api/LibraryApi";
import { FetchHomeDataApi } from "./api/fetchHomeDataApi";
import { PermissionApi } from "./api/PermissionApi";
import { QaApi } from "./api/QaApi";
import { RoleApi } from "./api/RoleApi";
import { StatusApi } from "./api/StatusApi";

import { UserApi } from "./api/UserApi";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['users'],
};

const persistedReducer = persistReducer( persistConfig,combineReducers({
  users: userSlice,
  [ResetPasswordApi.reducerPath]: ResetPasswordApi.reducer,
  [LibraryApi.reducerPath]:LibraryApi.reducer,
  [StatusApi.reducerPath]:StatusApi.reducer,
  [QaApi.reducerPath]:QaApi.reducer,
  [FetchHomeDataApi.reducerPath]: FetchHomeDataApi.reducer,
  [RoleApi.reducerPath] : RoleApi.reducer,
  [PermissionApi.reducerPath] : PermissionApi.reducer,
  [UserApi.reducerPath] : UserApi.reducer,

}));

export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
    .concat(ResetPasswordApi.middleware)
    .concat(LibraryApi.middleware)
    .concat(StatusApi.middleware)
    .concat(QaApi.middleware)
    .concat(FetchHomeDataApi.middleware).concat(RoleApi.middleware) 
    .concat(FetchHomeDataApi.middleware).concat(PermissionApi.middleware)
    .concat(FetchHomeDataApi.middleware).concat(UserApi.middleware)

  },

});

setupListeners(store.dispatch);
export const persistor = persistStore(store);