import React, { useState } from 'react';
import { BackArrow } from '../../../assets/customIcons/customIcons';
import OTPInput from 'react-otp-input';
import { useConfirmEmailCodeMutation } from '../../../store/api/ResetPassword';
import { useSnackbar } from '../../../utils/Provider/SnackbarContext';

function OtpPage({ setStep, email }) {
    const { showSnackbar } = useSnackbar(); // Access showSnackbar function from the context
    const [otp, setOtp] = useState('');
    const [confrimCode, { isLoading }] = useConfirmEmailCodeMutation();

    const submitData = async () => {
        if (!otp.trim()) {
            showSnackbar('error', 'Please enter the OTP code.');
            return;
        }

        try {
            const response = await confrimCode({ email, code:otp }); // Send email and OTP to confirm code
            if (response.data.status) {
                // Handle success response
                showSnackbar('success', response.data.message);
                setStep(2);
            } else {
                // Handle error response
                showSnackbar('error', response.data.message);
            }
        } catch (error) {
            // Handle error response
            console.error('Error confirming email code:', error);
            showSnackbar('error', 'An error occurred while confirming the email code. Please try again.');
        }
    };

    return (
        <div className='space-y-8 xl:space-y-[45px] w-full'>
            <div className='space-y-4'>
                <div className='flex items-center gap-1'>
                    <span onClick={() => setStep(0)}>
                        <BackArrow />
                    </span>
                    <h1 className='text-[32px] font-bold uppercase text-white'>Confirm Email</h1>
                </div>
                <p className='text-white text-base font-normal'>Please enter the code below we just sent to your email address</p>
            </div>
            <div>
                <div className='space-y-4'>
                    <label className="text-lg font-medium text-white" htmlFor="email-input">
                        One time code
                    </label>
                    <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={5}
                        renderSeparator={<span style={{ padding: '22px', color: 'white', fontSize: '18px', fontWeight: '500' }}>-</span>}
                        renderInput={(props) => <input {...props} inputMode='numeric' />}
                        isInputNum={true}
                        inputStyle={{
                            borderRadius: "0.5rem",
                            width: "100%",
                            height: "80px",
                            fontSize: "1.375rem",
                            fontWeight: "400",
                            border: '1px solid #787878',
                            background: "transparent",
                            color: "white",
                        }}
                    />
                </div>
            </div>
            <div>
                <button
                    onClick={submitData}
                    disabled={isLoading}
                    className='gradient_button py-2 px-2 w-full hover:bg-opacity-90 italic disabled:opacity-50'>
                    {isLoading ? 'Confirming...' : 'Confirm'}
                </button>
            </div>
        </div>
    );
}

export default OtpPage;
