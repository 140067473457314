import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
const UserPermissionTable = ({ data, headCells, isLoading }) => {
  const page = 0;
  const rowsPerPage = 100;
  function convertToTitleCaseAndSpaces(str) {
    return str.replace(/_/g, ' ')
              .replace(/\b\w/g, c => c.toUpperCase());
  }
  return (
    <div className="mt-[32px]">
      <TableContainer className="w-[100%]">
        <Table>
          <TableHead className="!rounded-[10px] ">
            <TableRow>
              {headCells?.map((cell, i) => {
                return (
                  <TableCell
                    key={'item-' + i}
                    sx={{ fontSize: 18, fontWeight: 700, whiteSpace: "nowrap", textTransform: 'uppercase' }}
                    className={`${i === 0 && "!rounded-tl-[10px] !rounded-bl-[10px]"
                      }  ${i === headCells.length - 1 &&
                      "!rounded-tr-[10px] !rounded-br-[10px]"
                      }`}
                  >
                    {cell.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell colSpan={headCells?.length}></TableCell>
            </TableRow>

            {isLoading ? (
              <TableRow>
                <TableCell colSpan={headCells.length}>
                  <LinearProgress color="primary" />
                </TableCell>
              </TableRow>
            ) : (
              data
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, count) => {
                  return (
                    <TableRow
                      key={'item-' + count}
                      className={`${count % 2 === 0 && "!bg-[#2D2D2D] !rounded-[10px]"
                        } `}
                    >
                      {headCells?.map((cell, i) => {
                        return (
                          <TableCell
                            key={'item-' + i}
                            className={`${i === 0 && "!rounded-tl-[10px] !rounded-bl-[10px]"
                              } ${i === headCells.length - 1 &&
                              "!rounded-tr-[10px] !rounded-br-[10px]"
                              }`}
                          >
                            {cell.id === "edit" ?
                              // <>
                              //  <button onClick={()=>handleDelete(row.id)}>Delete</button>
                              // </>
                              <></>
                              :
                              cell.id === "permissions" ?
                                <>
                                  {row[cell.id].map((obj) => {
                                    return <h2>{obj.name}</h2>
                                  })}</>

                                : convertToTitleCaseAndSpaces(row[cell.id])}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>

  );
};

export default UserPermissionTable;
