import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

function PrivateRoutes({ children }) {
    const { isSigned } = useSelector((state) => state?.users)
    if (isSigned) {
        return children
    }
    else {
        return <Navigate to={"/"} />
    }
}

export default PrivateRoutes