import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// const token=localStorage.getItem("xperi_userToken");
const RoleApi = createApi({
  reducerPath: "RoleApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/api`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      const token = state.users.token
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchList: builder.query({
        query: (data) => {
          return {
            url: `/all/roles?page=${data?.page+1}`,
            body:{per_page:data?.per_page},
            method: "POST",
          };
        },
        providesTags:["getroles"]
      }),
      fetchListSearch: builder.query({
        query: (data) => {
          return {
            url: `/role/search/advance?page=${data?.page+1}`,
            body:{
              per_page:data?.per_page,
              keyword:data?.searchQuery
            },
            method: "POST",
          };
        },
        providesTags:["getroles"]
      }),
      fetchRoleList: builder.mutation({
        query: (data) => {
          return {
            url: `/all/roles?page=${data?.page+1}`,
            body:{per_page:data?.per_page},
            method: "POST",
          };
        },
        providesTags:["getroles"]
      }),
      fetchRoleSearch: builder.mutation({
        query: (data) => {
          return {
            url: `/role/search/advance?page=${data?.page+1}`,
            body:{
              per_page:data?.per_page,
              keyword:data?.searchQuery
            },
            method: "POST",
          };
        },
        providesTags:["getroles"]
      }),
     CreateRole: builder.mutation({
        query: (data) => {
          return {
            url: "/role/add",
            body:data,
            method: "POST",
          };
        },
        invalidatesTags:["getroles"]
      }),
     UpdateRole: builder.mutation({
        query: (data) => {
          return {
            url: "/role/update",
            body:data,
            method: "POST",
          };
        },
        invalidatesTags:["getroles"]
      }),
      RemoveRole: builder.mutation({
        query: (id) => {
          return {
            url: "/role/delete",
            body:{id:id} , 
            method: "POST",
          };
        },
        invalidatesTags:["getroles"]
      }),
 
    };
  },
});

export const {useFetchListQuery , useCreateRoleMutation ,useRemoveRoleMutation,useUpdateRoleMutation ,useFetchListSearchQuery , useFetchRoleListMutation,useFetchRoleSearchMutation} = RoleApi;
export {RoleApi}