import React, { useEffect, useState } from "react";
import CommonTable from "../CommonTable/CommonTable";
import { QaHeadCells } from "../../../Raw/raw";
import {
  useFetchQaDropdownQuery,
  useFetchQaHistoryMutation,
  useFetchQaListMutation,
  useSelectAllMutation,
  useUpdateQaRowMutation,
} from "../../../store/api/QaApi";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { useSnackbar } from "../../../utils/Provider/SnackbarContext";

function Qa({ refreshData }) {
  const { showSnackbar } = useSnackbar();
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [selectedQaId, setSelectedQaId] = useState(null);
  const [previousDocumentId, setPreviousDocumentId] = useState(null);
  const [documentData, setDocumentData] = useState([]);
  const [QaData, setQaData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [fetchListData, { isLoading }] = useFetchQaListMutation();
  const [MarkAllReview] = useSelectAllMutation();
  const [updateData] = useUpdateQaRowMutation();
  const [fetchQaHistory, { isLoading: isFetchingQaHistory }] =
    useFetchQaHistoryMutation();
  const { data: fetchDropdown, isSuccess } = useFetchQaDropdownQuery();
  const userData = useSelector((state) => state.users.data);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const qa_history_id = localStorage.getItem("qa_history_id");
  const [Headcells, setHeadcells] = useState([]);

  useEffect(() => {
    const Headcells =
      userData?.roles[0]?.name !== "QA" ? QaHeadCells.slice(1) : QaHeadCells;
    setHeadcells(Headcells);
    if (userData?.roles[0]?.name !== "QA") {
      if (qa_history_id) {
        const data = {
          tdo_id: qa_history_id,
          per_page: rowsPerPage,
          page: page + 1,
        };
        fetchQaHistory(data).then((result) => {
          setTableData(result?.data?.data?.data);
          setTotalCount(result?.data?.data?.total);
        });
      } else {
        if (isSuccess && fetchDropdown && fetchDropdown?.data.length > 0) {
          setQaData(fetchDropdown?.data);
          const defaultQaId = selectedQaId
            ? selectedQaId
            : fetchDropdown?.data[0].id;
          if (defaultQaId !== null && defaultQaId !== previousDocumentId) {
            setSelectedQaId(defaultQaId);
            setPreviousDocumentId(defaultQaId);

            const selectedUser = fetchDropdown?.data?.find(
              (user) => user.id === defaultQaId
            );

            if (selectedUser) {
              // Access and log the documents of the selected user
              // console.log(selectedUser.documents);
              setDocumentData(selectedUser.documents);
              setSelectedDocumentId(selectedUser.documents[0].tdo_id);
            }
            const data = {
              tdo_id: selectedUser.documents[0].tdo_id,
              qa_id: defaultQaId,
              per_page: rowsPerPage,
              page,
            };
            fetchListData(data).then((result) => {
              setTableData(result?.data?.data?.data);
              setTotalCount(result?.data?.data?.total);
            });
          }
        }
      }
    } else {
      if (isSuccess && fetchDropdown && fetchDropdown?.data.length > 0) {
        const defaultDocumentId = selectedDocumentId
          ? selectedDocumentId
          : fetchDropdown?.data[0].tdo_id;
        if (
          defaultDocumentId !== null &&
          defaultDocumentId !== previousDocumentId
        ) {
          setDocumentData(fetchDropdown?.data);
          setSelectedDocumentId(defaultDocumentId);
          setPreviousDocumentId(defaultDocumentId);
          const data = {
            tdo_id: defaultDocumentId,
            per_page: rowsPerPage,
            page,
          };
          fetchListData(data).then((result) => {
            setTableData(result?.data?.data?.data);
            setTotalCount(result?.data?.data?.total);
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [isSuccess, refreshData, rowsPerPage, page]);

  const UpdateData = (updatedObj) => {
    const NewObj = { ...updatedObj };
    if (updatedObj.Select_all) {
      for (const key in NewObj) {
        // Check if the value is 0 and update to 1
        if (NewObj[key] === 0) {
          NewObj[key] = 1;
        }
      }
    }
    const updatedData = tableData.map((obj) =>
      obj.id === NewObj.id ? NewObj : obj
    );
    setTableData(updatedData);

    updateData(NewObj).then((result) => {
      // console.log("🚀 ~ updateData ~ result:", result);
      if (result?.data?.key) {
        showSnackbar("success","All records have been reviewed.")
        setTimeout(() => {
        window.location.reload();
        }, 2000);
      }
      else if (result?.data?.data) {
        setTableData(result?.data?.data?.data);
        setTotalCount(result?.data?.data?.total);
        showSnackbar("error", "The first 10 records did not pass. Please check the next ones.");
      }
    });
  };
  const MarkAll = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to mark all reviews as complete?"
    );
    if (isConfirmed) {
      const data = {
        hash_id: tableData[0]?.hash_id,
      };

      MarkAllReview(data).then((response) => {
        showSnackbar("success", response?.data?.message);
        if (response?.data?.key) {
          window.location.reload();
        }
      });
    }
  };
  const handleQaDropdownChange = (event) => {
    const selectedId = parseInt(event.target.value);
    setSelectedQaId(selectedId);
    const selectedUser = fetchDropdown?.data?.find(
      (user) => user.id === selectedId
    );

    if (selectedUser) {
      setDocumentData(selectedUser.documents);
      setSelectedDocumentId(selectedUser.documents[0].tdo_id);

      const data = {
        tdo_id: selectedUser.documents[0].tdo_id,
        qa_id: selectedId,
        per_page: rowsPerPage,
        page,
      };
      fetchListData(data).then((result) => {
        setTableData(result?.data?.data?.data);
        setTotalCount(result?.data?.data?.total);
      });
    } else {
      console.log("User not found");
    }
  };
  const handleDropdownChange = (event) => {
    const selectedId = parseInt(event.target.value);
    setSelectedDocumentId(selectedId);
    if (userData?.roles[0]?.name !== "QA") {
      const data = {
        tdo_id: selectedId,
        qa_id: selectedQaId,
        per_page: rowsPerPage,
        page,
      };
      fetchListData(data).then((result) => {
        setTableData(result?.data?.data?.data);
        setTotalCount(result?.data?.data?.total);
      });
    } else {
      const data = {
        tdo_id: selectedId,
        per_page: rowsPerPage,
        page,
      };
      fetchListData(data).then((result) => {
        setTableData(result?.data?.data?.data);
        setTotalCount(result?.data?.data?.total);
      });
    }
  };

  return (
    <>
      {!qa_history_id && (
        <div className="flex gap-5">
          {userData?.roles[0]?.name !== "QA" && (
            <div className="flex flex-col gap-4 w-52">
              <InputLabel id="qa-select-label"> Select QA </InputLabel>
              <Select
                labelId="qa-select-label"
                id="qa-select"
                value={selectedQaId || ""}
                onChange={handleQaDropdownChange}
                style={{
                  color:
                    selectedQaId === ""
                      ? "rgba(255, 255, 255, 0.60)"
                      : "inherit",
                }}
                //  renderValue={(value) => (value === '' ? 'Placeholder' : value)}
              >
                {QaData?.map((qa) => (
                  <MenuItem key={qa.id} value={qa.id}>
                    {qa.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}

          <div className="flex flex-col gap-4 w-52">
            <InputLabel id="document-select-label">Select Document</InputLabel>
            <Select
              labelId="document-select-label"
              id="document-select"
              value={selectedDocumentId || ""}
              onChange={handleDropdownChange}
              style={{
                color:
                  selectedDocumentId === ""
                    ? "rgba(255, 255, 255, 0.60)"
                    : "inherit",
              }}
              //  renderValue={(value) => (value === '' ? 'Placeholder' : value)}
            >
              {documentData.map((document) => (
                <MenuItem key={document.tdo_id} value={document.tdo_id}>
                  {document.document_name || document.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      )}

      <CommonTable
        data={tableData}
        totalCount={totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        headCells={Headcells}
        isLoading={isLoading || isFetchingQaHistory}
        updateRowHandler={UpdateData}
        MarkAll={MarkAll}
        tableName={"QA"}
      />
    </>
  );
}

export default Qa;
