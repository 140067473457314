import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../axios/axios';

const logoutUser = createAsyncThunk("user/logout", async () => {

  const token=localStorage.getItem("xperi_userToken");
   
    try {
        const response = await axios.post(`/logout`,  null,
            {
                headers: {
                    "Content-Type": "application/json", // Adjust the content type based on your API requirements
                    'Authorization': `Bearer ${token}`
                },
            }
        );

        return response.data;
    }
    catch (error) {
       
        if (error.response) {
            const errorMessage = error.response.data.message ;
            throw new Error(errorMessage);
        } 
        else if(error.message){
            throw new Error(error.message);
        }
        else {
            throw new Error("An error occurred while processing your request.");
        }
    }
}
);

export { logoutUser };
