

export const UploadIcon = () =>{

    return <svg width="22" height="22" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.21 8.21002L11.5 5.91002V15.5C11.5 15.7652 11.6054 16.0196 11.7929 16.2071C11.9804 16.3947 12.2348 16.5 12.5 16.5C12.7652 16.5 13.0196 16.3947 13.2071 16.2071C13.3946 16.0196 13.5 15.7652 13.5 15.5V5.91002L15.79 8.21002C15.883 8.30375 15.9936 8.37814 16.1154 8.42891C16.2373 8.47968 16.368 8.50582 16.5 8.50582C16.632 8.50582 16.7627 8.47968 16.8846 8.42891C17.0064 8.37814 17.117 8.30375 17.21 8.21002C17.3037 8.11706 17.3781 8.00645 17.4289 7.8846C17.4797 7.76274 17.5058 7.63203 17.5058 7.50002C17.5058 7.36801 17.4797 7.2373 17.4289 7.11544C17.3781 6.99358 17.3037 6.88298 17.21 6.79002L13.21 2.79002C13.1149 2.69898 13.0028 2.62761 12.88 2.58002C12.6365 2.48 12.3635 2.48 12.12 2.58002C11.9972 2.62761 11.8851 2.69898 11.79 2.79002L7.79 6.79002C7.69676 6.88326 7.6228 6.99395 7.57234 7.11577C7.52188 7.23759 7.49591 7.36816 7.49591 7.50002C7.49591 7.63188 7.52188 7.76245 7.57234 7.88427C7.6228 8.00609 7.69676 8.11678 7.79 8.21002C7.88324 8.30326 7.99393 8.37722 8.11575 8.42768C8.23757 8.47814 8.36814 8.50411 8.5 8.50411C8.63186 8.50411 8.76243 8.47814 8.88425 8.42768C9.00607 8.37722 9.11676 8.30326 9.21 8.21002ZM21.5 12.5C21.2348 12.5 20.9804 12.6054 20.7929 12.7929C20.6054 12.9804 20.5 13.2348 20.5 13.5V19.5C20.5 19.7652 20.3946 20.0196 20.2071 20.2071C20.0196 20.3947 19.7652 20.5 19.5 20.5H5.5C5.23478 20.5 4.98043 20.3947 4.79289 20.2071C4.60536 20.0196 4.5 19.7652 4.5 19.5V13.5C4.5 13.2348 4.39464 12.9804 4.20711 12.7929C4.01957 12.6054 3.76522 12.5 3.5 12.5C3.23478 12.5 2.98043 12.6054 2.79289 12.7929C2.60536 12.9804 2.5 13.2348 2.5 13.5V19.5C2.5 20.2957 2.81607 21.0587 3.37868 21.6213C3.94129 22.1839 4.70435 22.5 5.5 22.5H19.5C20.2956 22.5 21.0587 22.1839 21.6213 21.6213C22.1839 21.0587 22.5 20.2957 22.5 19.5V13.5C22.5 13.2348 22.3946 12.9804 22.2071 12.7929C22.0196 12.6054 21.7652 12.5 21.5 12.5Z" fill="white"/>
    </svg>
    

}


export const FilterIcon = () =>{
return<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.00005 5.75C6.41427 5.75 6.75005 6.08579 6.75005 6.50001L6.74998 16.9918C6.74998 17.1566 6.94911 17.2392 7.06568 17.1228L7.1892 16.9994C7.48227 16.7068 7.95701 16.707 8.24986 16.9998C8.54286 17.2928 8.54286 17.7679 8.24986 18.0609L6.35361 19.9571C6.15835 20.1524 5.84177 20.1524 5.6465 19.9571L3.75023 18.0608C3.45724 17.7678 3.45724 17.2928 3.75023 16.9998C4.04308 16.707 4.51784 16.7068 4.81089 16.9995L4.93432 17.1228C5.05087 17.2392 5.24998 17.1566 5.24998 16.9919L5.25005 6.5C5.25005 6.08578 5.58584 5.75 6.00005 5.75ZM12.7501 17C13.1643 17 13.5001 17.3358 13.5001 17.75C13.5001 18.1642 13.1643 18.5 12.7501 18.5H10.5001C10.0858 18.5 9.75006 18.1642 9.75006 17.75C9.75006 17.3358 10.0858 17 10.5001 17H12.7501ZM15.0001 13.25C15.4143 13.25 15.7501 13.5858 15.7501 14C15.7501 14.4142 15.4143 14.75 15.0001 14.75H10.5001C10.0858 14.75 9.75006 14.4142 9.75006 14C9.75006 13.5858 10.0858 13.25 10.5001 13.25H15.0001ZM17.2501 9.5C17.6643 9.5 18.0001 9.83579 18.0001 10.25C18.0001 10.6642 17.6643 11 17.2501 11H10.5001C10.0858 11 9.75006 10.6642 9.75006 10.25C9.75006 9.83579 10.0858 9.5 10.5001 9.5H17.2501ZM19.5001 5.75C19.9143 5.75 20.2501 6.08579 20.2501 6.5C20.2501 6.91421 19.9143 7.25 19.5001 7.25H10.5001C10.0858 7.25 9.75006 6.91421 9.75006 6.5C9.75006 6.08579 10.0858 5.75 10.5001 5.75H19.5001Z" fill="white"/>
</svg>
}

export const DownArrow = () =>{

    return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 10C18 10.2 17.9333 10.3333 17.8 10.4667L12.4667 15.8C12.3333 15.9333 12.1333 16 12 16C11.8 16 11.6667 15.9333 11.5333 15.8L6.2 10.4667C5.93333 10.2 5.93333 9.8 6.2 9.53333C6.46667 9.26667 6.86667 9.26667 7.13333 9.53333L12 14.4L16.8667 9.53333C17.1333 9.26667 17.5333 9.26667 17.8 9.53333C17.9333 9.66667 18 9.8 18 10Z" fill="white"/>
    </svg>
}

export const SearchIcon =()=>{
    return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M4.74707 11.5588C4.74707 8.07278 7.57302 5.24683 11.059 5.24683C14.545 5.24683 17.371 8.07278 17.371 11.5588C17.371 13.2906 16.6735 14.8596 15.5441 16C15.5365 16.007 15.5291 16.0141 15.5218 16.0214C15.5145 16.0287 15.5073 16.0362 15.5004 16.0437C14.3599 17.1732 12.7909 17.8707 11.059 17.8707C7.57302 17.8707 4.74707 15.0448 4.74707 11.5588ZM16.0273 17.5876C14.677 18.7015 12.9462 19.3707 11.059 19.3707C6.7446 19.3707 3.24707 15.8732 3.24707 11.5588C3.24707 7.24435 6.7446 3.74683 11.059 3.74683C15.3734 3.74683 18.871 7.24435 18.871 11.5588C18.871 13.4459 18.2018 15.1767 17.0879 16.5269L20.5341 19.9731C20.827 20.266 20.827 20.7408 20.5341 21.0337C20.2412 21.3266 19.7663 21.3266 19.4734 21.0337L16.0273 17.5876Z" fill="white"/>
</svg>

}


export const AddIcon = () =>{
    return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 11.5H13V5.5C13 5.23478 12.8946 4.98043 12.7071 4.79289C12.5196 4.60536 12.2652 4.5 12 4.5C11.7348 4.5 11.4804 4.60536 11.2929 4.79289C11.1054 4.98043 11 5.23478 11 5.5V11.5H5C4.73478 11.5 4.48043 11.6054 4.29289 11.7929C4.10536 11.9804 4 12.2348 4 12.5C4 12.7652 4.10536 13.0196 4.29289 13.2071C4.48043 13.3946 4.73478 13.5 5 13.5H11V19.5C11 19.7652 11.1054 20.0196 11.2929 20.2071C11.4804 20.3946 11.7348 20.5 12 20.5C12.2652 20.5 12.5196 20.3946 12.7071 20.2071C12.8946 20.0196 13 19.7652 13 19.5V13.5H19C19.2652 13.5 19.5196 13.3946 19.7071 13.2071C19.8946 13.0196 20 12.7652 20 12.5C20 12.2348 19.8946 11.9804 19.7071 11.7929C19.5196 11.6054 19.2652 11.5 19 11.5Z" fill="white"/>
    </svg>
    
}


export const CrossIcon = () =>{

    return <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.6891 20.7248C22.1036 21.1398 22.1036 21.7853 21.6891 22.2003C21.4818 22.4078 21.2284 22.5 20.952 22.5C20.6756 22.5 20.4223 22.4078 20.215 22.2003L16 17.9813L11.785 22.2003C11.5777 22.4078 11.3244 22.5 11.048 22.5C10.7716 22.5 10.5182 22.4078 10.3109 22.2003C9.89635 21.7853 9.89635 21.1398 10.3109 20.7248L14.5259 16.5058L10.3109 12.2867C9.89635 11.8718 9.89635 11.2262 10.3109 10.8112C10.7255 10.3963 11.3704 10.3963 11.785 10.8112L16 15.0303L20.215 10.8112C20.6296 10.3963 21.2745 10.3963 21.6891 10.8112C22.1036 11.2262 22.1036 11.8718 21.6891 12.2867L17.4741 16.5058L21.6891 20.7248Z" fill="white"/>
    </svg>
    
}


export const ThreeDots = () =>{
    return <svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18" fill="none">
    <path d="M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4Z" fill="#9D9D9D"/>
    <path d="M2 18C3.10457 18 4 17.1046 4 16C4 14.8954 3.10457 14 2 14C0.89543 14 0 14.8954 0 16C0 17.1046 0.89543 18 2 18Z" fill="#9D9D9D"/>
    <path d="M2 11C3.10457 11 4 10.1046 4 9C4 7.89543 3.10457 7 2 7C0.89543 7 0 7.89543 0 9C0 10.1046 0.89543 11 2 11Z" fill="#9D9D9D"/>
  </svg>
}

export const EditIcon = (props) =>{
return  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <path d="M19.4003 7.8401L16.6603 5.1001C16.3027 4.7642 15.8341 4.57146 15.3436 4.55855C14.8532 4.54565 14.3751 4.71347 14.0003 5.0301L5.0003 14.0301C4.67706 14.3561 4.4758 14.7833 4.4303 15.2401L4.0003 19.4101C3.98683 19.5566 4.00583 19.7042 4.05596 19.8425C4.10608 19.9808 4.1861 20.1063 4.2903 20.2101C4.38374 20.3028 4.49455 20.3761 4.61639 20.4259C4.73823 20.4756 4.86869 20.5009 5.0003 20.5001H5.0903L9.2603 20.1201C9.71709 20.0746 10.1443 19.8733 10.4703 19.5501L19.4703 10.5501C19.8196 10.1811 20.0084 9.68861 19.9953 9.18064C19.9822 8.67266 19.7682 8.19061 19.4003 7.8401ZM9.0803 18.1201L6.0803 18.4001L6.3503 15.4001L12.0003 9.8201L14.7003 12.5201L9.0803 18.1201ZM16.0003 11.1801L13.3203 8.5001L15.2703 6.5001L18.0003 9.2301L16.0003 11.1801Z" fill="white"/>
</svg>
}

export const DeleteIcon = (props)=>{
    return <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M16.3817 22.3449H7.61753C7.00125 22.3467 6.40646 22.1185 5.94956 21.7049C5.49267 21.2914 5.20654 20.7222 5.14718 20.1087L3.72787 5.8751C3.71617 5.76003 3.72876 5.64379 3.76484 5.5339C3.80091 5.424 3.85966 5.32292 3.93729 5.23717C4.01492 5.15143 4.10969 5.08295 4.21546 5.03617C4.32124 4.98938 4.43566 4.96533 4.55132 4.96558H19.4479C19.5635 4.96559 19.6778 4.98981 19.7834 5.03668C19.8891 5.08355 19.9837 5.15204 20.0613 5.23772C20.1389 5.3234 20.1976 5.42439 20.2338 5.53416C20.27 5.64394 20.2828 5.76008 20.2713 5.8751L18.852 20.1079C18.7927 20.7214 18.5066 21.2907 18.0497 21.7044C17.5928 22.1181 16.998 22.3465 16.3817 22.3449ZM5.46498 6.62075L6.79408 19.9441C6.81404 20.1485 6.90946 20.3381 7.06172 20.476C7.21397 20.6139 7.41213 20.6901 7.61753 20.6897H16.3817C16.5871 20.6901 16.7852 20.6139 16.9375 20.476C17.0897 20.3381 17.1852 20.1485 17.2051 19.9441L18.5342 6.62075H5.46498Z" fill="white"/>
    <path d="M21.5173 6.62069H2.48286C2.26337 6.62069 2.05287 6.5335 1.89767 6.37829C1.74247 6.22309 1.65527 6.01259 1.65527 5.7931C1.65527 5.57361 1.74247 5.36311 1.89767 5.20791C2.05287 5.05271 2.26337 4.96552 2.48286 4.96552H21.5173C21.7368 4.96552 21.9473 5.05271 22.1025 5.20791C22.2577 5.36311 22.3449 5.57361 22.3449 5.7931C22.3449 6.01259 22.2577 6.22309 22.1025 6.37829C21.9473 6.5335 21.7368 6.62069 21.5173 6.62069ZM12.0001 19.0345C11.7806 19.0345 11.5701 18.9473 11.4149 18.7921C11.2597 18.6369 11.1725 18.4264 11.1725 18.2069V9.10345C11.1725 8.88396 11.2597 8.67346 11.4149 8.51825C11.5701 8.36305 11.7806 8.27586 12.0001 8.27586C12.2196 8.27586 12.4301 8.36305 12.5853 8.51825C12.7405 8.67346 12.8277 8.88396 12.8277 9.10345V18.2069C12.8277 18.4264 12.7405 18.6369 12.5853 18.7921C12.4301 18.9473 12.2196 19.0345 12.0001 19.0345ZM9.10272 19.0345C8.89626 19.0343 8.69733 18.9569 8.54498 18.8176C8.39262 18.6783 8.29785 18.487 8.27927 18.2814L7.45169 9.17793C7.43191 8.95945 7.49969 8.74205 7.64014 8.57352C7.78058 8.40499 7.98219 8.29912 8.20065 8.27917C8.41926 8.25984 8.63663 8.32788 8.80521 8.46839C8.97379 8.6089 9.07987 8.81045 9.10024 9.02896L9.92783 18.1324C9.93818 18.247 9.92453 18.3624 9.88776 18.4714C9.85099 18.5804 9.7919 18.6806 9.71426 18.7655C9.63662 18.8503 9.54214 18.9181 9.43684 18.9644C9.33155 19.0107 9.21775 19.0346 9.10272 19.0345ZM14.8975 19.0345C14.7824 19.0346 14.6687 19.0107 14.5634 18.9644C14.4581 18.9181 14.3636 18.8503 14.2859 18.7655C14.2083 18.6806 14.1492 18.5804 14.1124 18.4714C14.0757 18.3624 14.062 18.247 14.0724 18.1324L14.9 9.02896C14.9422 8.57379 15.3394 8.24607 15.7995 8.27917C16.018 8.29912 16.2196 8.40499 16.3601 8.57352C16.5005 8.74205 16.5683 8.95945 16.5485 9.17793L15.7209 18.2814C15.7023 18.487 15.6076 18.6783 15.4552 18.8176C15.3029 18.9569 15.1039 19.0343 14.8975 19.0345Z" fill="white"/>
    <path d="M15.7241 6.62067H8.27583C8.05634 6.62067 7.84584 6.53348 7.69064 6.37827C7.53543 6.22307 7.44824 6.01257 7.44824 5.79308V2.48274C7.44824 2.26325 7.53543 2.05275 7.69064 1.89755C7.84584 1.74234 8.05634 1.65515 8.27583 1.65515H15.7241C15.9436 1.65515 16.1541 1.74234 16.3093 1.89755C16.4645 2.05275 16.5517 2.26325 16.5517 2.48274V5.79308C16.5517 6.01257 16.4645 6.22307 16.3093 6.37827C16.1541 6.53348 15.9436 6.62067 15.7241 6.62067ZM9.10341 4.9655H14.8965V3.31032H9.10341V4.9655Z" fill="white"/>
    </svg>
}

export const PlusIcon = () =>{
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path d="M19 11.5H13V5.5C13 5.23478 12.8946 4.98043 12.7071 4.79289C12.5196 4.60536 12.2652 4.5 12 4.5C11.7348 4.5 11.4804 4.60536 11.2929 4.79289C11.1054 4.98043 11 5.23478 11 5.5V11.5H5C4.73478 11.5 4.48043 11.6054 4.29289 11.7929C4.10536 11.9804 4 12.2348 4 12.5C4 12.7652 4.10536 13.0196 4.29289 13.2071C4.48043 13.3946 4.73478 13.5 5 13.5H11V19.5C11 19.7652 11.1054 20.0196 11.2929 20.2071C11.4804 20.3946 11.7348 20.5 12 20.5C12.2652 20.5 12.5196 20.3946 12.7071 20.2071C12.8946 20.0196 13 19.7652 13 19.5V13.5H19C19.2652 13.5 19.5196 13.3946 19.7071 13.2071C19.8946 13.0196 20 12.7652 20 12.5C20 12.2348 19.8946 11.9804 19.7071 11.7929C19.5196 11.6054 19.2652 11.5 19 11.5Z" fill="white"/>
  </svg>
    }
export const BackIcon = () =>{
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path d="M9 6.5C9.2 6.5 9.33333 6.56667 9.46667 6.7L14.8 12.0333C14.9333 12.1667 15 12.3667 15 12.5C15 12.7 14.9333 12.8333 14.8 12.9667L9.46667 18.3C9.2 18.5667 8.8 18.5667 8.53333 18.3C8.26667 18.0333 8.26667 17.6333 8.53333 17.3667L13.4 12.5L8.53333 7.63333C8.26666 7.36667 8.26666 6.96667 8.53333 6.7C8.66666 6.56667 8.8 6.5 9 6.5Z" fill="#797979" stroke="#797979"/>
  </svg>
}


export const libraryHeadCells  = [

    // {id:"id" , label :"ID" , isChecked:true},
    {id:"document_name" , label :"TITLE" , isChecked:true},
    {id:"created_at" , label :"DATE UPLOADED" , isChecked:true},
    {id:"tasks" , label :"PROGRAM TYPE" , isChecked:true},
    // {id:"edit", label :"" , isChecked:true}


]

export const UserRoleCell  = [
    {id:"name" , label :"ROLES" , isChecked:true,},
    {id:"permissions" , label :"PERMISSIONS" , isChecked:true,},
    {id:"edit", label :"" , isChecked:true}
]


export const UserHeadCell  = [
    {id:"name" , label :"USERNAME" , isChecked:true,},
    {id:"email" , label :"EMAIL" , isChecked:true,},
    {id:"roles" , label :"ROLES" , isChecked:true,},
    {id:"language_names" , label :"LANGUAGES" , isChecked:true,},
  
    {id:"edituser", label :"" , isChecked:true}
]


export const UserPermissionCell  = [
    {id:"name" , label :"NAME" , isChecked:true,},
    // {id:"created_at" , label :"CREATED AT" , isChecked:true,},
    // {id:"edit", label :"" , isChecked:true}
]

export const statusHeadCells  = [

    // {id:"id" , label :"ID" , isChecked:true},
    {id:"document_name" , label :"Uploaded document" , isChecked:true},
    {id:"qa_user" , label :"QA person" , isChecked:true},
    {id:"status" , label :"Status" , isChecked:true},
    {id:"data_count" , label :"Data Count" , isChecked:true},
    {id:"time_since_uploaded", label :"Time since uploaded" , isChecked:true},
    {id:"", label :"Download button" , isChecked:true ,type:'download_btn'},
    {id:"edit_qa", label :"Edit QA" , isChecked:true }


]
export const QaHeadCells  = [
    {id:'Select_all',label:'Select',type:'checkbox'},
    {id:"Program_ID" , label :"Program ID" , isChecked:true,type:'text'},
    {id:"Title" , label :"TITLE" , isChecked:true,type:'text'},
    {id:"Import_4000_copy" , label :"Import 4000 copy" , isChecked:true,type:'text'},
    {id:"Transformed_Data" , label :"Transformed Data" , isChecked:true,type:'text'},
    {id:"No_banned_words" , label :"No banned words" , isChecked:true,type:'checkbox'},
    {id:"Grammatically_Correct" , label :"Grammatically Correct" , isChecked:true,type:'checkbox'},
    {id:"No_cast_or_crew_names" , label :"No Cast Or Crew names" , isChecked:true,type:'checkbox'},
    {id:"No_show_title" , label :"No show title" , isChecked:true,type:'checkbox'},
    {id:"Culturally_Accurate", label :"Culturally accurate" , isChecked:true,type:'checkbox'},
    {id:"No_or_", label :"No ! or ?" , isChecked:true,type:'checkbox'},
    {id:"No_Spoilers", label :"No Spoilers" , isChecked:true,type:'checkbox'},
    {id:"PASSES", label :"Passes" , isChecked:true,type:'checkbox'},
    {id:"Review_Complete", label :"Review Complete" , isChecked:true,type:'checkbox'},
    {id:"Translator_Feedback", label :"QA Feedback" , isChecked:true,type:'input'},


]


