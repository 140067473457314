import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "black",
  border: "2px solid #000",
  boxShadow: 24,
  color: "#2d2d2d",
  borderRadius: "24px",
  background:
    "radial-gradient(129.62% 164.52% at 106.15% 5.08%, rgba(0, 0, 0, 0.08) 11.75%, rgba(98, 38, 167, 0.10) 44.3%, rgba(228, 7, 125, 0.10) 86.9%)",
  backdropFilter: "blur(40px)",
  p: 4,
};

export default function BasicModal({ value, data, keyname, updateRowHandler }) {
  const [inputValue, setInputValue] = React.useState(value || "");
  const [feedbackValue, setfeedbackValue] = React.useState(value || "");
  const [open, setOpen] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
    setInputValue(feedbackValue);
  };
  const userData = useSelector((state) => state.users.data);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleSubmit = () => {
    // Add logic to handle submission, e.g., updating state or sending data to parent component
    const newData = { ...data };
    newData[keyname] = inputValue;
    updateRowHandler(newData);
    setOpen(false);
    setfeedbackValue(inputValue);
  };

  useEffect(() => {
    setfeedbackValue(value);
  }, [value]);

  useEffect(() => {
    if (userData?.roles[0]?.name !== "QA") {
      setIsAdmin(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="cursor-pointer" onClick={isAdmin ? () => {} : handleOpen}>
        {feedbackValue && feedbackValue?.trim() !== "" ? (
          <div className="max-w-24 whitespace-pre-line">{feedbackValue}</div>
        ) : (
          "Click for Feedback"
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <textarea
            type="text"
            className="form_input w-full px-3 py-4 bg-white bg-opacity-[0.05] rounded-lg text-white resize-none"
            value={inputValue}
            placeholder="Write here"
            onChange={handleChange}
            readOnly={isAdmin}
          />
          <div className="flex gap-4 w-full pt-4">
            {!isAdmin && (
              <>
                <Button
                  onClick={handleClose}
                  sx={{ fontStyle: "italic" }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  sx={{ fontStyle: "italic" }}
                  variant="gradient"
                >
                  Submit
                </Button>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
