import {
  Checkbox,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import TableRowMenu from "./tableRowMenu";
import TableRowCheckbox from "./tableRowCheckbox";
import TableRowInputBox from "./tableRowInputBox";
import { DeleteIcon, EditIcon } from "../../../Raw/raw";
import DownloadButton from "./DownloadButton";
// import EditStatusQA from "../Status/EditStatusQA";
import { InfoIcon } from "../../../assets/customIcons/customIcons";

const CommonTable = ({
  data,
  headCells,
  isLoading,
  handleRemove,
  updateRowHandler,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  MarkAll,
  setTabName,
  handleEditUser,
  nodata,
  tableName,
}) => {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleViewHistory = (tdo_id) => {
    // console.log(tdo_id)
    localStorage.setItem("qa_history_id", tdo_id);
    setTabName("QA");
  };
  const qaHistoryId =  localStorage.getItem("qa_history_id")
  return (
    <div className="mt-[32px]">
      <TableContainer className="w-[100%]">
        <Table>
          <TableHead className="!rounded-[10px] ">
            <TableRow>
              {headCells?.map((cell, i) => {
                return (
                  <TableCell
                    key={"item-" + i}
                    sx={{
                      fontSize: 14,
                      fontWeight: 700,
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                    }}
                    className={`${
                      i === 0 && "!rounded-tl-[10px] !rounded-bl-[10px]"
                    }  ${
                      i === headCells?.length - 1 &&
                      "!rounded-tr-[10px] !rounded-br-[10px] "
                    }
                      ${
                        cell?.type === "checkbox" && cell?.id !== "Select_all"
                          ? "vertical_heading "
                          : ""
                      }
                      ${cell.type === "download_btn" ? "!text-center " : ""}
                      `}
                  >
                    {
                      // cell?.id ==='Select_all' ?
                      //   <>
                      //   Select all
                      //   <Checkbox
                      //   onClick={MarkAll}
                      //   />

                      //   </>
                      // :
                      cell.label
                    }
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell colSpan={headCells?.length}></TableCell>
            </TableRow>
              {/* {console.log("data " , data)} */}
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={headCells?.length}>
                  <LinearProgress color="primary" />
                </TableCell>
              </TableRow>
            ) : (
              data
                // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, count) => {
                  return (
                    <TableRow
                      key={"item-" + count}
                      className={`${
                        count % 2 === 0 && "!bg-[#2D2D2D] !rounded-[10px]"
                      } `}
                    >
                      {headCells?.map((cell, i) => {
                        return (
                          <TableCell
                            style={{ wordBreak: "unset" }}
                            key={"item-" + i}
                            className={`${
                              i === 0 && "!rounded-tl-[10px] !rounded-bl-[10px]"
                            } ${
                              i === headCells.length - 1 &&
                              "!rounded-tr-[10px] !rounded-br-[10px] "
                            }  ${
                              cell.type === "download_btn" ? "!text-center" : ""
                            } break-all`}
                          >
                            {cell?.type === "checkbox" ? (
                              <TableRowCheckbox
                                updateRowHandler={updateRowHandler}
                                data={row}
                                keyname={cell.id}
                                isChecked={row[cell.id]}
                              />
                            ) : cell?.type === "input" ? (
                              <TableRowInputBox
                                updateRowHandler={updateRowHandler}
                                data={row}
                                keyname={cell.id}
                                value={row[cell.id]}
                              />
                            ) : cell.type === "download_btn" ? (
                              <>
                                <DownloadButton
                                  status={row?.status}
                                  response_document_id={row?.response_document}
                                  output_description={row?.output_description}
                                />
                              </>
                            ) : cell.id === "edit" ? (
                              <>
                                <TableRowMenu
                                  id={row?.id}
                                  handleRemoveClicked={() =>
                                    handleRemove(row?.id)
                                  }
                                />
                                {/* {console.log(row.id)} */}
                              </>
                            ) : cell.id === "edituser" ? (
                              <>
                                <div className="flex gap-4">
                                  <div onClick={() => handleEditUser(row)}>
                                    <EditIcon className="cursor-pointer" />
                                  </div>
                                  <div onClick={() => handleRemove(row?.id)}>
                                    <DeleteIcon className="cursor-pointer" />
                                  </div>
                                </div>
                              </>
                            ) : cell.id === "edit_qa" ? (
                              <>
                                {(row?.status === "In QA" ||
                                  row?.status === "Pending") && (
                                  <div onClick={() => handleEditUser(row)}>
                                    <EditIcon className="cursor-pointer" />
                                  </div>
                                )}
                                {(row?.status === "Completed" ||
                                  row?.status === "Regenerate") && (
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => handleViewHistory(row?.id)}
                                  >
                                    <Tooltip title="View History">
                                      <IconButton sx={{ padding: 0 }}>
                                        <InfoIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                )}
                              </>
                            ) : cell.id === "language_names" ? (
                              row[cell.id]?.join(",")
                            ) : cell.id === "roles" ? (
                              row[cell.id][0]?.name
                            ) : cell.id === "tasks" ? (
                              row?.tasks[0]?.name
                            ) : (
                              row[cell.id]
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
        {nodata && !isLoading && data?.length === 0 && (
          <div className="text-center">No Data Found</div>
        )}
      </TableContainer>
      {tableName === "QA" & !qaHistoryId ? (
        <div className="flex items-center justify-end pt-3 gap-5 text-sm">
          <div> Rows per page: 10</div>
          <div>1-10</div>
        </div>
      ) : (
        <TablePagination
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      )}
      {/* <EditUserModal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        selectedUser={selectedUserId}
        setRefreshData={setRefreshData}
      />     */}
    </div>
  );
};

export default CommonTable;
