import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// const token=localStorage.getItem("xperi_userToken");
const FetchHomeDataApi = createApi({
  reducerPath: "homedata",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/api`,
    prepareHeaders: (headers, { getState,method }) => {
      const state = getState();
      const token = state.users.token
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      // headers.set("Content-Type", "application/json");
      if (method === 'POST' && headers.get('Content-Type') !== 'multipart/form-data') {
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      getLanguage: builder.query({
        query: () => {
          return {
            url: "/languages",
            method: "GET",
          };
        },
        providesTags:["getlanguages"]
      }),
      getTaskList: builder.query({
        query: () => {
          return {
            url: "/task/list",
            body:{},
            method: "POST",
          };
        },
        providesTags:["gettasks"]
      }),
      getQaList: builder.query({
        query: (data) => {
          return {
            url: "/qa/users",
            body:data,
            method: "POST",
          };
        },
        providesTags:["getqalist"]
      }),
      submitData: builder.mutation({
        query: (formData) => {
          return {
            url: "/document/create",
            body: formData,
            method: "POST",
          };
        },
      }),
    };
  },
});

export const {useGetLanguageQuery ,useGetTaskListQuery ,useGetQaListQuery ,useSubmitDataMutation} = FetchHomeDataApi;
export {FetchHomeDataApi}