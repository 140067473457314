import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
// import { Stack } from '@mui/system';
import { CrossIcon } from '../../../Raw/raw';
import { useCreatePermissionMutation } from '../../../store/api/PermissionApi';
import { useSnackbar } from '../../../utils/Provider/SnackbarContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 472,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: "24px",
  p: 4,
  background: "radial-gradient(129.62% 164.52% at 106.15% 5.08%, rgba(0, 0, 0, 0.08) 11.75%, rgba(98, 38, 167, 0.10) 44.3%, rgba(228, 7, 125, 0.10) 86.9%)",
  backdropFilter: "blur(40px)"
};

export default function AddPermissionModal() {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [savePermission ,setsavePermission] =React.useState("");
  const {showSnackbar} = useSnackbar()
  const handleOpen = () => {setOpen(true);setsavePermission('')}

const [createPermission ,{isLoading}] = useCreatePermissionMutation();

const handleSavePermission=()=>{
  createPermission({name:savePermission}).unwrap().then((result)=>{
    
    if (result.status) {
      setOpen(false);
      setsavePermission('')
      showSnackbar('success', result.message)
    }
    else {
      showSnackbar('error', result.message)
    }
  })
  .catch((error)=>{
    console.error('Failed to create user:', error);
    if (error?.data?.errors) {
      const firstErrorKey = Object.keys(error?.data?.errors)[0];
      const firstErrorMessage = error?.data?.errors[firstErrorKey][0];
      showSnackbar('error', firstErrorMessage)
    } 
    else {
      showSnackbar('error', error?.data?.message)
    }
  })


}

  return (
    <div>
      <Button onClick={handleOpen} className='flex !min-w-[200px] gap-x-[12px] !px-[24px] !py-[8px] ' variant='pinkpurplegradient' >
       
     + ADD PERMISSION
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='flex justify-between items-center'><h2 className='text-[32px] font-[700] uppercase mb-6'>Create Permission</h2> 
          <div className='cursor-pointer' onClick={()=>setOpen(false)}>
          <CrossIcon /> 

          </div>
          </div>
          <div className='flex flex-col gap-y-[20px]'>
            
              <React.Fragment >
                <div className='flex flex-col gap-y-[16px]'>
                  <label className="text-base   font-bold text-white" htmlFor="email-input">
                   Name
                  </label>
                  <input
                    className='form_input w-full px-3 py-4 h-[50px] bg-white bg-opacity-[0.05] rounded-lg text-white'
                    type="text"
                    name="name"
                    id="email-input"
                  
                    value={savePermission}
                    onChange={
                      (e)=>{
                      setsavePermission(e.target.value) ;                     
                    }     
                    }
                    placeholder='Permission '
                    autoComplete="off"
                  /></div>
              </React.Fragment>
          
          </div>
          <div className='mt-[22px] flex justify-between gap-4'>
            <Button variant="outlined"onClick={()=>setOpen(false)}>Cancel</Button>
            <Button
            disabled={isLoading}
            onClick={()=>handleSavePermission()}  variant="gradient">Save</Button>
          </div>

        </Box>
      </Modal>
    </div>
  );
}