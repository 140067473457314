import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import { FaCheckCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import { AddIcon, CrossIcon } from "../../Raw/raw";
import {
  useCreateUserMutation,
  useGetLanguageQuery,
} from "../../store/api/UserApi";
import BasicSelect from "./SelectDropdown";
import SelectCheckBoxDropdown from "./CheckBoxDropdown";
import { useFetchListQuery } from "../../store/api/RoleApi";
import { useSnackbar } from "../../utils/Provider/SnackbarContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 472,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "24px",
  p: 4,
  background:
    "radial-gradient(129.62% 164.52% at 106.15% 5.08%, rgba(0, 0, 0, 0.08) 11.75%, rgba(98, 38, 167, 0.10) 44.3%, rgba(228, 7, 125, 0.10) 86.9%)",
  backdropFilter: "blur(40px)",
  overflowY: "auto", // Enable vertical scrolling
  maxHeight: "90vh", // Set maximum height to allow scrolling
};

// Custom styled tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#C0E7FE",
    color: "black",
    paddingBlock: "1rem",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function BasicModal({ setRefreshData }) {
  const [CreateUser] = useCreateUserMutation();
  const [open, setOpen] = React.useState(false);


  const { showSnackbar } = useSnackbar();
  const { data: fetchRole } = useFetchListQuery();
  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [language, setLanguage] = React.useState([]);
  const [role, setRole] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [passwordsMatch, setPasswordsMatch] = React.useState(false);

  const handleOpen = () => {
    setFullName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setLanguage([]);
    setRole("");
    setOpen(true);
  };

  const { data: fetchLanguage } = useGetLanguageQuery();

  const handleUser = () => {
    let languageIds;
    if (language.includes("Select All")) {
      languageIds = fetchLanguage.data.map((obj) => String(obj.id));
    } else {
      languageIds = language.map((name) => {
        return String(fetchLanguage.data.filter((x) => x.name === name)[0].id);
      });
    }

    CreateUser({
      user_name: fullName,
      email: email,
      language: languageIds,
      password: password,
      password_confirmation: confirmPassword,
      role: role,
    })
      .unwrap()
      .then((result) => {
        if (result.status) {
          setOpen(false);
          setFullName("");
          setEmail("");
          setPassword("");
          setLanguage([]);
          setConfirmPassword("");
          setRole("");
          showSnackbar("success", result.message);
          setRefreshData((prev) => !prev);
        } else {
          showSnackbar("error", result.message);
        }
      })
      .catch((error) => {
        console.error("Failed to create user:", error);
        if (error?.data?.errors) {
          const firstErrorKey = Object.keys(error?.data?.errors)[0];
          const firstErrorMessage = error?.data?.errors[firstErrorKey][0];
          showSnackbar("error", firstErrorMessage);
        } else {
          showSnackbar("error", error?.data?.message);
        }
      });
  };

  const [uppercase, setUppercase] = React.useState(false);
  const [specialChar, setSpecialChar] = React.useState(false);
  const [lengthCheck, setLengthCheck] = React.useState(false);
  const handleClose = () => {
    setUppercase(false)
    setSpecialChar(false)
    setLengthCheck(false)
    setPasswordsMatch(false)
    setOpen(false)
  };
  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    setUppercase(/[A-Z]/.test(value));
    setSpecialChar(/[!@#$%^&*]/.test(value));
    setLengthCheck(value.length > 8);
    setPasswordsMatch(value !== "" && confirmPassword !== "" && value === confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    setPasswordsMatch(password !== "" && value !== "" && value === password);
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        className="flex !min-w-[200px] gap-x-[12px] !px-[24px] !py-[8px] whitespace-nowrap"
        variant="pinkpurplegradient"
      >
        <AddIcon />
        Create New
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-[32px] font-[700] uppercase">Create User</h2>
            <div className="cursor-pointer" onClick={() => setOpen(false)}>
              <CrossIcon />
            </div>
          </div>
          <div className="flex flex-col gap-y-[20px]">
            <div className="flex flex-col gap-y-[16px]">
              <label
                className="text-base font-bold text-white"
                htmlFor="fullname-input"
              >
                Full Name
              </label>
              <input
                className="form_input w-full px-3 py-4 bg-white bg-opacity-[0.05] rounded-lg text-white"
                type="text"
                name="name"
                id="fullname-input"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                autoComplete="off"
              />
              <label
                className="text-base font-bold text-white"
                htmlFor="email-input"
              >
                Email
              </label>
              <input
                className="form_input w-full px-3 py-4 bg-white bg-opacity-[0.05] rounded-lg text-white"
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email-input"
                autoComplete="off"
              />
              <label
                className="text-base font-bold text-white"
                htmlFor="password-input"
              >
                Password
              </label>
              <CustomTooltip
                title={
                  <div>
                    <Typography color={"black"} marginBottom={1}>
                      Password must contain the following:
                    </Typography>
                    <Typography color={uppercase ? "green" : "grey"}>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {uppercase && (
                          <FaCheckCircle
                            style={{ color: "green", marginRight: "0.5rem" }}
                          />
                        )}
                        1 Uppercase letter
                      </span>
                    </Typography>
                    <Typography color={specialChar ? "green" : "grey"}>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {specialChar && (
                          <FaCheckCircle
                            style={{ color: "green", marginRight: "0.5rem" }}
                          />
                        )}
                        1 Special character - !@#$%^&*
                      </span>
                    </Typography>
                    <Typography color={lengthCheck ? "green" : "grey"}>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {lengthCheck && (
                          <FaCheckCircle
                            style={{ color: "green", marginRight: "0.5rem" }}
                          />
                        )}
                        Must be longer than 8 characters
                      </span>
                    </Typography>
                  </div>
                }
                arrow
              >
                <TextField
                  className="form_input w-full  bg-opacity-[0.05] rounded-lg text-white"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password-input"
                  value={password}
                  onChange={handlePasswordChange}
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword ? (
                          <FaEye
                            fontSize={22}
                            cursor={"pointer"}
                            color="white"
                            onClick={toggleShowPassword}
                          />
                        ) : (
                          <FaEyeSlash
                            fontSize={22}
                            cursor={"pointer"}
                            color="white"
                            onClick={toggleShowPassword}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </CustomTooltip>
            </div>
            <label
              className="text-base font-bold text-white"
              htmlFor="confirmpasinput"
            >
              Confirm Password
            </label>
            <CustomTooltip
              title={
                <div>
                  <Typography color={"black"} marginBottom={1}>
                    Password must contain the following:
                  </Typography>
                  <Typography color={passwordsMatch ? "green" : "grey"}>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {passwordsMatch && (
                        <FaCheckCircle
                          style={{ color: "green", marginRight: "0.5rem" }}
                        />
                      )}
                      Passwords must match
                    </span>
                  </Typography>
                </div>
              }
              arrow
            >
              <TextField
                className="form_input w-full  bg-opacity-[0.05] rounded-lg text-white"
                type={showConfirmPassword ? "text" : "password"}
                id="confirmpasinput"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showConfirmPassword ? (
                        <FaEye
                          fontSize={22}
                          cursor={"pointer"}
                          color="white"
                          onClick={toggleShowConfirmPassword}
                        />
                      ) : (
                        <FaEyeSlash
                          fontSize={22}
                          cursor={"pointer"}
                          color="white"
                          onClick={toggleShowConfirmPassword}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </CustomTooltip>
            <label className="text-base font-bold text-white">Role</label>
            <BasicSelect
              role={role}
              setRole={setRole}
              fetchRole={fetchRole?.data}
            />
            <label className="text-base font-bold text-white">Language</label>
            <div className="w-full">
              <SelectCheckBoxDropdown
                data={fetchLanguage?.data}
                personName={language}
                setPersonName={setLanguage}
                name="language"
              />
            </div>
          </div>
          <div className="mt-[22px] flex justify-between gap-4">
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="gradient" onClick={handleUser}>
              Save
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
