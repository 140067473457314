import React, { useEffect, useState } from 'react';
import Dashboardtabs from './Dashboardtabs';
import logo from '../../assets/images/xperi-logo-white.svg';
import { Avatar, Menu, MenuItem } from '@mui/material';
import { LogoutIcon, ProfileIcon } from '../../assets/customIcons/customIcons';
import LogoutModal from './LogoutModal';
import { useSelector } from 'react-redux';
import Roles from './Roles/Roles';
import Home from './Home/Home'
import Library from './Library/Library'
import Users from './Users/Users'
import Status from './Status/Status'
import Qa from './QA/Qa'
import Permission from './Roles/Permission';
import axios from '../../store/axios/axios';
import { useSnackbar } from '../../utils/Provider/SnackbarContext';


function MainPage() {
  const userPermissions = useSelector((state) => state.users.permissions);
  const [value, setValue] = useState(0); // State to manage the active tab index
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [tabName, setTabName] = useState('');
  const [userImage, setUserImage] = useState(null);
  const userData = useSelector((state) => state?.users?.data);
  const { showSnackbar } = useSnackbar();
  const baseUrl =process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    GetImage()
   // eslint-disable-next-line 
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutCancel = () => {
    setLogoutOpen(false);
  };

  const handleLogout = () => {
    setLogoutOpen(true);
    handleMenuClose();
  };

  const handleImageInputChange = (event) => {
    const imageFile = event.target.files[0];
    handleImageUpload(imageFile)
    
  };

  const GetImage = async (message) => {
    try {
      
      const token=localStorage.getItem("xperi_userToken");
     
      const response = await axios.post('/profile/show', {}, {
        headers: {
          "Content-Type": "application/json", // Adjust the content type based on your API requirements
            'Authorization': `Bearer ${token}`
        }
       
      });
      if(response?.data?.status){
        if(message){
          showSnackbar('success',message)
        }
        setUserImage(response?.data?.data?.profile_image)
      }
   
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };
  const handleImageUpload = async (imageFile) => {
    try {
      const formData = new FormData();
      formData.append('image', imageFile);
      const token=localStorage.getItem("xperi_userToken");
      const response = await axios.post('/profile/images', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
       
      });

      if(response?.data?.status){
        console.log(response?.data?.message)
        GetImage(response?.data?.message)
        handleMenuClose();
      }
      else{
        showSnackbar('error',response?.data?.message)
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const ActiveTab = (tabName) => {
    if (tabName === 'QA') {
      localStorage.removeItem('qa_history_id');
      setRefreshData((prev) => !prev);
    }
    setTabName(tabName);
  };

  useEffect(() => {
    let defaultTabIndex = ''; // Default index when no permissions are available

    if (userPermissions?.includes('view_home')) {
      defaultTabIndex = 'Home';
    } else if (userPermissions?.includes('view_library')) {
      defaultTabIndex = 'Library';
    } else if (userPermissions?.includes('view_user')) {
      defaultTabIndex = 'Users';
    } else if (userPermissions?.includes('view_status')) {
      defaultTabIndex = 'Status';
    } else if (userPermissions?.includes('QA_view')) {
      defaultTabIndex = 'QA';
    }

    setTabName(defaultTabIndex);
  }, [userPermissions]);

  const handleLogoClick = () => {
    setValue(0);
    if (userPermissions?.includes('view_home')) {
      setTabName('Home');
    }
  };

  return (
    <>
      <div className='px-3 md:px-5 xl:px-[100px]'>
        <div className='flex justify-center sm:justify-between py-5'>
          <img
            className='w-24 md:w-40 hidden sm:block cursor-pointer'
            onClick={handleLogoClick}
            src={logo}
            alt='Xperi'
          />
          <div>
            <Dashboardtabs ActiveTab={ActiveTab} userPermissions={userPermissions} value={value} setValue={setValue} />
          </div>
          <>
            <div onClick={handleMenuOpen} className='cursor-pointer items-center gap-3 text-xs md:text-lg font-normal hidden sm:flex'>
              {userImage ? 
              (
                <Avatar src={`${baseUrl}/profile/${userImage}`} alt={userData?.name} />
              ) : (
                <Avatar>{userData?.name[0]}</Avatar>
              )}
              {userData?.name}
            </div>
            <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
              <MenuItem>
                <label htmlFor='imageUpload' className='flex items-center gap-2 cursor-pointer'>
                  <ProfileIcon />
                  Upload Image
                </label>
                <input
                  id='imageUpload'
                  type='file'
                  accept='image/*'
                  onChange={handleImageInputChange}
                  style={{ display: 'none' }}
                />
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <span className='flex items-center gap-2'>
                  <LogoutIcon />
                  Logout
                </span>
              </MenuItem>
            </Menu>
          </>
        </div>
        <div className='pt-6 pb-40'>
          {(tabName === 'Home' && userPermissions?.includes('view_home')) && <Home />}
          {(tabName === 'Library' && userPermissions?.includes('view_library')) && <Library />}
          {(tabName === 'Users' && userPermissions?.includes('view_user')) && <Users />}
          {(tabName === 'Status' && userPermissions?.includes('view_status')) && <Status setTabName={setTabName} />}
          {(tabName === 'QA' && userPermissions?.includes('QA_view')) && <Qa refreshData={refreshData} />}
          {(tabName === 'Roles' && userPermissions?.includes('view_role')) && <Roles />}
          {(tabName === 'Permission' && userPermissions?.includes('view_permission')) && <Permission setTabName={setTabName} />}
        </div>
      </div>

      <LogoutModal open={logoutOpen} onClose={handleLogoutCancel} />
    </>
  );
}

export default MainPage;
