import React, { useState } from 'react'
import UserPermissionTable from './UserPermissionTable'
import { UserPermissionCell } from '../../../Raw/raw'
import { useFetchListQuery } from '../../../store/api/PermissionApi'
// import { Breadcrumbs, Button } from '@mui/material'
import AddPermissionModal from './PermissionModal'
import BasicBreadcrumbs from './BreadCrumbs'
// import Dashboardtabs from './Dashboardtabs'
import Roles from './Roles'
function Permission() {
    const { data: fetchList, isFetching } = useFetchListQuery();

    // const [isModalOpen, setIsModalOpen] = useState(false);

    // const openModal = () => {
    //     setIsModalOpen(true);
    // };

    // const closeModal = () => {
    //     setIsModalOpen(false);
    // };

    
    const [BreadCrumbsBtn , setBreadCrumbsBtn] =useState(false);
    if(BreadCrumbsBtn){
        return  <Roles/>
    }
    return (
        <>
      
        <div className='BreadCrumpAddCustomer'>
         <div><BasicBreadcrumbs  setBreadCrumbsBtn={setBreadCrumbsBtn}/>  </div>   
        <div >
                  
                <AddPermissionModal 
                // onClose={closeModal}
                 />
            </div>
        </div>
            <UserPermissionTable data={fetchList?.data} headCells={UserPermissionCell} isLoading={isFetching} />
        </>
    )
}

export default Permission