import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import { CloseIcon } from '../../../assets/customIcons/customIcons';
import { useGetQaListQuery } from '../../../store/api/fetchHomeDataApi';
import { useChangeQaMutation } from '../../../store/api/StatusApi';
import { useSnackbar } from '../../../utils/Provider/SnackbarContext';



function EditStatusQA({ open, onClose, qaUserData,setRefreshData, selectedQAPerson, setSelectedQAPerson}) {
    // console.log(qaUser)
    const { showSnackbar } = useSnackbar();
    
    const { data: QaList, } = useGetQaListQuery({ language_id: qaUserData?.language_id })
    const [changeQa,{isLoading}] = useChangeQaMutation()
 

    const handleSubmit = () => {
        const data = {
            id: qaUserData?.id,
            qa_id: selectedQAPerson,

        }
      
        changeQa(data).then((result) => {
            if (result?.data?.status) {
                onClose();
                setRefreshData((prev)=>!prev)
                showSnackbar('success', result?.data?.message);
            }
            else {
                showSnackbar('error', result?.data?.message);
            }
        })
        .catch ((error)=>{
            console.error('Error submitting form:', error);
            showSnackbar('error', 'An error occurred while processing your request.');
        })

    }
    const handleChange = (event) => {
        const { value } = event.target;
        
        setSelectedQAPerson(value);
    };
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle sx={{ fontSize: '32px', fontWeight: 700, display: 'flex', justifyContent: 'end', padding: 0, paddingBottom: '16px' }}>

                <IconButton sx={{ position: 'absolute', top: '5px', right: '5px' }} aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ color: '#ffff', paddingRight: 0, paddingLeft: 0 }}>
                <div className='flex flex-col gap-4'>
                    <InputLabel id="selected_qa_person">Change QA Person</InputLabel>
                    <Select
                        labelId="selected_qa_person"
                        id="selected_qa_person"
                        value={QaList?.data?.length>0 ? selectedQAPerson :'' }
                        name="selectedQAPerson"
                        displayEmpty
                        style={{ color: selectedQAPerson === '' ? 'rgba(255, 255, 255, 0.60)' : 'inherit' }}
                        className='!bg-white !bg-opacity-[0.05]'
                        //  renderValue={(value) => (value === '' ? 'Placeholder' : value)}
                        onChange={handleChange}
                    >
                        {
                            QaList?.data?.map((item) => (
                                <MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem>
                            ))
                        }
                    </Select>
                </div>
            </DialogContent>
            <DialogActions>
                <div className='flex gap-4 w-full'>
                    <Button onClick={onClose} sx={{ fontStyle: 'italic' }} variant='outlined'>
                        Cancel
                    </Button>
                    <Button
                    disabled={isLoading}
                    onClick={handleSubmit} sx={{ fontStyle: 'italic' }} variant='gradient'>
                        Submit
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}

export default EditStatusQA;
