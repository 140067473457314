import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// const token=localStorage.getItem("xperi_userToken");
const StatusApi = createApi({
  reducerPath: "statusApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/api`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      const token = state.users.token
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchStatusList: builder.mutation({
        query: (data) => {
          return {
            url: `/document/status?page=${data?.page+1}`,
            body:{per_page:data?.per_page},
            method: "POST",
          };
        },
      }),
      fetchStatusSearch: builder.mutation({
        query: (data) => {
          return {
            url: `/status/search?page=${data?.page+1}`,
            body:{
              per_page:data?.per_page,
              keyword:data?.searchQuery
            },
            method: "POST",
          };
        },
      }),
      StatusDownload: builder.mutation({
        query: (response_document_id) => {
          return {
            url: "/document/download",
            body:{response_document_id},
            method: "POST",
          };
        },
      }),
      ChangeQa: builder.mutation({
        query: (data) => {
          return {
            url: "/change/qa",
            body:data,
            method: "POST",
          };
        },
      }),
    };
  },
});

export const {useFetchStatusListMutation,useStatusDownloadMutation ,useChangeQaMutation ,useFetchStatusSearchMutation} = StatusApi;
export {StatusApi}