import React, { useState } from 'react';
import LoginWrapper from './LoginWrapper';
import { BackArrow } from '../../../assets/customIcons/customIcons';
import { Link } from 'react-router-dom';
import OtpPage from './OtpPgae';
import ResetPassword from './ResetPassword';
import { useForgetPasswordMutation } from '../../../store/api/ResetPassword';
import { useSnackbar } from '../../../utils/Provider/SnackbarContext';

function ForgetPassword() {
    const { showSnackbar } = useSnackbar();
    const [step, setStep] = useState(0);
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});

    const [forgotPassword,{isLoading:isForgotLoading}] = useForgetPasswordMutation();
   
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmail(value);
        // Clear any previous error messages for this field
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const validateForm = () => {
        const errors = {};

        if (!email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Invalid email format';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            
            try {
                // Call API to initiate forgot password process
                const response = await forgotPassword({ email });
                console.log(response)
               
                if(response?.data?.status){
                    // Handle success response or transition to the next step
                    showSnackbar('success',response?.data?.message);
                    setStep(1);
                }
               
                else{
                    showSnackbar('error',response?.data?.message);
                }
            } catch (error) {
                // Handle error response
                console.error('Error submitting form:', error);
                showSnackbar('error', 'An error occurred while processing your request.');
            }
          
        }
    };

    return (
        <LoginWrapper>
            {step === 0 && (
                <div className='space-y-8 xl:space-y-[45px] w-full'>
                    <div className='space-y-4'>
                        <div className='flex items-center gap-1'>
                            <Link to='/'>
                                <BackArrow />
                            </Link>
                            <h1 className='text-[32px] font-bold uppercase text-white'>Forgot password?</h1>
                        </div>
                        <p className='text-white text-base font-normal'>Please enter your registered email address</p>
                    </div>
                    <div>
                        <div className='space-y-4'>
                            <label className='text-lg font-medium text-white' htmlFor='email-input'>
                                Email
                            </label>
                            <input
                                className='form_input w-full px-3 py-4 bg-white bg-opacity-[0.05] rounded-lg text-white'
                                type='email'
                                name='email'
                                id='email-input'
                                placeholder='Example@email.com'
                                value={email}
                                onChange={handleChange}
                            />
                            {errors.email && <div className='text-red-500'>{errors.email}</div>}
                        </div>
                    </div>
                    <div>
                        <button
                            onClick={handleSubmit}
                            className='gradient_button py-2 px-2 w-full hover:bg-opacity-90 italic disabled:opacity-50'
                            disabled={isForgotLoading}>
                            Confirm
                        </button>
                    </div>
                </div>
            )}
            {step === 1 && <OtpPage email={email} setStep={setStep} />}
            {step === 2 && <ResetPassword email={email} setStep={setStep} />}
        </LoginWrapper>
    );
}

export default ForgetPassword;
