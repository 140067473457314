import React, { 
    // useEffect, 
    useState } from 'react'
import FileUploader from '../FileUploader'
import { Button, InputLabel, MenuItem, Select } from '@mui/material'
import { useGetLanguageQuery, useGetQaListQuery, useGetTaskListQuery, useSubmitDataMutation } from '../../../store/api/fetchHomeDataApi';
import LibraryModal from './LibraryModal';
import { useSnackbar } from '../../../utils/Provider/SnackbarContext';
// import { useSelector } from 'react-redux';


function Home() {
    // const userPermissions = useSelector((state) => state.users.permissions);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedTask, setSelectedTask] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedQAPerson, setSelectedQAPerson] = useState('');
    const [isLibraryModalOpen, setIsLibraryModalOpen] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const { showSnackbar } = useSnackbar();
    const { data: LanguageList, } = useGetLanguageQuery()
    const { data: Taskist, } = useGetTaskListQuery()
    const { data: QaList, } = useGetQaListQuery({ language_id: selectedLanguage })

    const [submitFormData, { isLoading }] = useSubmitDataMutation()

    const handleChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'selectedTask':
                setSelectedTask(value);
                break;
            case 'selectedLanguage':
                setSelectedLanguage(value);
                break;
            case 'selectedQAPerson':
                setSelectedQAPerson(value);
                break;
            case 'selectedFile':
                setSelectedFile(value);
                break;

            default:
                break;
        }
    };

    const handleOpenLibraryModal = () => {
        setIsLibraryModalOpen(true);
    };

    const handleCloseLibraryModal = () => {
        setIsLibraryModalOpen(false);
    };
    const resetFile = () => {
        setCurrentFile(null)
        setSelectedFile(null)
    }

    const handleSubmit = async () => {
        try {
            const formData = new FormData();

            // Add selected file to FormData if it exists
            if (selectedFile) {
                formData.append('file', selectedFile);
            }

            // Add other form fields to FormData
            formData.append('task', selectedTask);
            formData.append('language_id', selectedLanguage);
            formData.append('qa_person', selectedQAPerson);

            const response = await submitFormData(formData);

            if (response?.data) {
                if (response?.data?.success) {
                    showSnackbar('success', response?.data?.message);
                    setSelectedQAPerson('')
                    setSelectedLanguage('')
                    setSelectedTask('')

                    setSelectedFile(null)
                    setCurrentFile(null)
                }

                else {
                    showSnackbar('error', response?.data?.message);
                }
            }
            else {

                if (response?.error?.data?.errors) {

                    const firstErrorKey = Object.keys(response?.error?.data?.errors)[0];
                    const firstErrorMessage = response?.error?.data?.errors[firstErrorKey][0];
                    showSnackbar('error', firstErrorMessage)
                }
                else {
                    showSnackbar('error', response?.error?.data?.message)
                }

            }

        } catch (error) {
            // Handle error response
            console.error('Error submitting form:', error);
            showSnackbar('error', 'An error occurred while processing your request.');
        }
    };
    const handleCancel = () => {
        setSelectedQAPerson('')
        setSelectedLanguage('')
        setSelectedTask('')
        setSelectedFile(null)
        setCurrentFile(null)
    }
    // useEffect(() => {
    //     console.log(QaList)
    //     if (QaList?.status === false && selectedLanguage && !userPermissions?.includes('view_qa_users') ) {
    //         showSnackbar('error', QaList?.message)
    //     }
    // }, [selectedLanguage])

    return (
        <div className='max-w-xl mx-auto'>
            <h1 className='text-center text-[32px] font-bold uppercase mb-2'>
                <span className='gradient_pink_purple_text'> Add document</span>
            </h1>
            <p className='text-center text-lg'>Upload a document to get the desired output</p>
            <div className='mt-6 p-6 bg-[#2d2d2d] rounded-2xl flex-col sm:flex-row flex justify-between items-center gap-4'>

                {
                    selectedFile || currentFile ?
                        <div className='tex-center w-full'>
                            <div className='font-bold text-center break-all'>{selectedFile?.name || currentFile}</div>
                            {/* <div className='text-[#9D9D9D] font-normal text-[14px]'>{selectedFile?.type}</div> */}

                            <div onClick={resetFile} className='text-white pt-4 gradient_pink_purple_text font-bold text-[14px] underline text-center cursor-pointer'>Change file</div>
                        </div>
                        :

                        <>
                            <FileUploader
                                selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}
                            />


                            <span className='text-opacity-60'>
                                OR
                            </span>

                            <div
                                className='bg-[#363636] h-52 rounded-xl border-[1px] px-2 border-[#5f5f5f] border-dashed min-h-40 w-full flex-1 flex items-center justify-center cursor-pointer text-center'
                                onClick={handleOpenLibraryModal}
                            >
                                <div className='font-bold' >

                                    {currentFile ?? 'Choose from library'}


                                </div>
                            </div>
                        </>
                }



            </div>
            <div className='mt-8 space-y-8'>
                <div className='flex flex-col gap-4'>
                    <InputLabel id="selected_task">Select Task</InputLabel>
                    <Select
                        labelId="selected_task"
                        id="selected_task"
                        value={selectedTask}
                        name="selectedTask"
                        displayEmpty
                        style={{ color: selectedTask === '' ? 'rgba(255, 255, 255, 0.60)' : 'inherit' }}
                        //  renderValue={(value) => (value === '' ? 'Placeholder' : value)}
                        onChange={handleChange}
                    >
                        {
                            Taskist?.data?.map((item) => (
                                <MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem>
                            ))
                        }
                    </Select>
                </div>
                <div className='flex flex-col gap-4'>
                    <InputLabel id="selected_language">Select Language</InputLabel>
                    <Select
                        labelId="selected_language"
                        id="selected_language"
                        value={selectedLanguage}
                        name="selectedLanguage"
                        displayEmpty
                        style={{ color: selectedLanguage === '' ? 'rgba(255, 255, 255, 0.60)' : 'inherit' }}
                        //  renderValue={(value) => (value === '' ? 'Placeholder' : value)}
                        onChange={handleChange}
                    >
                        {
                            LanguageList?.data?.map((item) => (
                                <MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem>
                            ))
                        }


                    </Select>
                </div>
                <div className='flex flex-col gap-4'>
                    <InputLabel id="selected_qa_person">Select QA Person</InputLabel>
                    <Select
                        labelId="selected_qa_person"
                        id="selected_qa_person"
                        value={selectedQAPerson}
                        name="selectedQAPerson"
                        displayEmpty
                        style={{ color: selectedQAPerson === '' ? 'rgba(255, 255, 255, 0.60)' : 'inherit' }}
                        //  renderValue={(value) => (value === '' ? 'Placeholder' : value)}
                        onChange={handleChange}
                    >
                        {
                            QaList?.data?.map((item) => (
                                <MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem>
                            ))
                        }
                    </Select>
                </div>
            </div>
            <LibraryModal isOpen={isLibraryModalOpen}
                handleClose={handleCloseLibraryModal}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                currentFile={currentFile}
                setCurrentFile={setCurrentFile}
            />

            <div className='mt-10 flex justify-between gap-4'>
                <Button
                    onClick={handleCancel}
                    variant="outlined">Cancel</Button>
                <Button
                    onClick={handleSubmit}
                    disabled={

                        isLoading} variant="contained">Generate</Button>
            </div>
        </div>
    )
}

export default Home