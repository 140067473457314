import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';

function Dashboardtabs({ value, setValue, userPermissions ,ActiveTab}) {

  const handleChange = (event, newValue) => {
    setValue(newValue); // Update the active tab index when a tab is clicked
  };

  return (
    <div>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#2d2d2d',
          borderRadius: '8px',
        }}
      >
        <Tabs
          TabIndicatorProps={{
            style: { display: 'none' }, // Hide the indicator
          }}
          value={value} // Set the active tab index
          onChange={handleChange} // Handle tab change
          centered
        >
          {
             userPermissions?.includes("view_home") &&

          <Tab
          onClick={()=>ActiveTab('Home')}
            sx={{
              '&.Mui-selected': {
                borderRadius: '8px',
              },

            }}
            label="Home"
          />
          }

          {
            userPermissions?.includes("view_library") &&
            <Tab
            onClick={()=>ActiveTab('Library')}
              sx={{
                '&.Mui-selected': {
                  borderRadius: '8px',
                },

              }}
              label="Library"
            />
          }
          {
            userPermissions?.includes("view_user") &&
            <Tab
            onClick={()=>ActiveTab('Users')}
              sx={{
                '&.Mui-selected': {
                  borderRadius: '8px',
                },

              }}
              label="Users"
            />
          }
         {
           userPermissions?.includes("view_status") &&
           <Tab
           onClick={()=>ActiveTab('Status')}
           sx={{
             '&.Mui-selected': {
               borderRadius: '8px',
             },

           }}
           label="Status"
         />
         }
          {
            userPermissions?.includes("QA_view") &&
            <Tab
            onClick={()=>ActiveTab('QA')}
              sx={{
                '&.Mui-selected': {
                  borderRadius: '8px',
                },

              }}
              label="QA"
            />
          }
            {
            userPermissions?.includes("view_role") &&
            <Tab
            onClick={()=>ActiveTab('Roles')}
              sx={{
                '&.Mui-selected': {
                  borderRadius: '8px',
                },

              }}
              label="Roles"
            />
          }
          
        </Tabs>
      </Box>
    </div>
  );
}

export default Dashboardtabs;
