import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  UserHeadCell,
} from "../../../Raw/raw";
import CommonTable from "../CommonTable/CommonTable";
import BasicModal from "../Modal";
import { useFetchListMutation, useFetchSearchListMutation, useGetLanguageQuery, useRemoveUserMutation } from "../../../store/api/UserApi";
import EditUserModal from "./EditUserModal";
import { useSnackbar } from "../../../utils/Provider/SnackbarContext";
import SearchToggleInput from "../SearchToggleInput";
function Users() {
  const [userData, setUserData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [refreshData, setRefreshData] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');
  const [nodata, setNodata]= useState(false)
  const [fullName, setfullName] = React.useState('');
  const [role, setRole] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [language, setlanguage] = React.useState([]);
  const [fetchList, { isLoading: isFetching }] = useFetchListMutation();
  const [fetchSearchList, { isLoading: isFetchingSearch }] = useFetchSearchListMutation();
  const { data: fetchlanguage, isSuccess } = useGetLanguageQuery();
  const [removeUser] = useRemoveUserMutation();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccess) {
      const data = {
        per_page: rowsPerPage,
        page,
        searchQuery
      };
      const fetchFunction = searchQuery ? fetchSearchList : fetchList; // Use fetchList for regular fetching, fetchSearchList for searching
      fetchFunction(data).then((result) => {
        const updatedData = result?.data?.data?.data?.map((obj) => {
          return { ...obj, language_names: fetchlanguage?.data?.filter(item => obj?.language_names?.includes(item.id))?.map(item => item.name) };
        });
        if(updatedData.length===0){
          setNodata(true)
        }
        setTotalCount(result?.data?.data?.total);
        setUserData(updatedData);
      });
    }
    // eslint-disable-next-line
  }, [page, rowsPerPage, isSuccess, refreshData, searchQuery]);


  const [open, setOpen] = useState(false);
  const [userIdToRemove, setUserIdToRemove] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleEditUser = (selectedUser) => {
    // console.log(user)
    setSelectedUserId(selectedUser);
    setfullName(selectedUser?.name || '')
    setEmail(selectedUser?.email || '')
    setlanguage(selectedUser?.language_names || [])
    setRole(selectedUser?.roles[0]?.id || '')
    setIsEditModalOpen(true);
  };

  const handleOpenDialog = (id) => {
    setUserIdToRemove(id);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setUserIdToRemove(null);
  };

  const handleRemoveUser = () => {
    removeUser(userIdToRemove).then((result) => {
      if (result?.data?.status) {
        showSnackbar('success', result?.data?.message);
        setRefreshData((prev) => !prev)
      } else {
        showSnackbar('error', result?.data?.message);
      }
    }).catch((error) => {
      console.log(error)
    });
    handleCloseDialog();
  };

  return (
    <>
      <div className="flex justify-between">
        <h2 className="text-[32px] font-[700] uppercase">ADMIN (Users)</h2>

        <div className="flex gap-x-4 h-12">
          <BasicModal
            setRefreshData={setRefreshData}
          />
          <SearchToggleInput

            setSearchQuery={setSearchQuery}
          />

        </div>
      </div>


      <CommonTable
        data={userData} totalCount={totalCount}
        page={page} setPage={setPage}
        rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage}
        headCells={UserHeadCell} isLoading={isFetching || isFetchingSearch} handleRemove={handleOpenDialog}
        setRefreshData={setRefreshData}
        handleEditUser={handleEditUser}
        nodata={nodata}
        tableName={"Users"}
      />
      <EditUserModal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        selectedUser={selectedUserId}
        setRefreshData={setRefreshData}
        language={language} setlanguage={setlanguage}
        email={email} setEmail={setEmail}
        role={role} setRole={setRole}
        fullName={fullName} setfullName={setfullName}
      />

      <>

        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            Are you sure you want to remove this user?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleRemoveUser}>Remove</Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
}

export default Users;
