import React, { useState } from "react";
import { BackArrow } from "../../../assets/customIcons/customIcons";
import { useResetPasswordMutation } from "../../../store/api/ResetPassword";
import { useSnackbar } from "../../../utils/Provider/SnackbarContext"; // Import your SnackbarContext
import { useNavigate } from "react-router-dom";
import { FaCheckCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#C0E7FE",
    color: "black",
    paddingBlock: "1rem",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

function ResetPassword({ setStep, email }) {
  const { showSnackbar } = useSnackbar(); // Access showSnackbar function from the context
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [uppercase, setUppercase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [lengthCheck, setLengthCheck] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const navigate = useNavigate();

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setNewPassword(value);
    setUppercase(/[A-Z]/.test(value));
    setSpecialChar(/[!@#$%^&*]/.test(value));
    setLengthCheck(value.length > 8);
    setPasswordsMatch(
      value !== "" && confirmPassword !== "" && value === confirmPassword
    );
  };

  const handleConfirmPasswordChange = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    setPasswordsMatch(
      newPassword !== "" && value !== "" && value === newPassword
    );
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleSubmit = async () => {
    if (!newPassword || !confirmPassword) {
      showSnackbar(
        "error",
        "Please enter both new password and confirm password."
      );
      return;
    }

    if (newPassword !== confirmPassword) {
      showSnackbar("error", "Passwords do not match.");
      return;
    }

    try {
      const response = await resetPassword({
        email,
        password: newPassword,
        confirm_password: confirmPassword,
      });
      if (response.data.status) {
        showSnackbar("success", response.data.message);
        navigate("/", { replace: true });
      } else {
        showSnackbar("error", response.data.message);
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      showSnackbar(
        "error",
        "An error occurred while resetting your password. Please try again."
      );
    }
  };

  return (
    <div className="space-y-8 xl:space-y-[45px] w-full">
      <div className="space-y-4">
        <div className="flex items-center gap-1">
          <span onClick={() => setStep(1)}>
            <BackArrow />
          </span>
          <h1 className="text-[32px] font-bold uppercase text-white">
            Reset password
          </h1>
        </div>
        <p className="text-white text-base font-normal">Set a new password</p>
      </div>
      <div>
        <div className="space-y-4 mt-6">
          <label
            className="text-lg font-medium text-white"
            htmlFor="new-password-input"
          >
            New Password
          </label>
          <CustomTooltip
            title={
              <div>
                <Typography color={"black"} marginBottom={1}>
                  Password must contain the following:
                </Typography>
                <Typography color={uppercase ? "green" : "grey"}>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {uppercase && (
                      <FaCheckCircle
                        style={{ color: "green", marginRight: "0.5rem" }}
                      />
                    )}
                    1 Uppercase letter
                  </span>
                </Typography>
                <Typography color={specialChar ? "green" : "grey"}>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {specialChar && (
                      <FaCheckCircle
                        style={{ color: "green", marginRight: "0.5rem" }}
                      />
                    )}
                    1 Special character - !@#$%^&*
                  </span>
                </Typography>
                <Typography color={lengthCheck ? "green" : "grey"}>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {lengthCheck && (
                      <FaCheckCircle
                        style={{ color: "green", marginRight: "0.5rem" }}
                      />
                    )}
                    Must be longer than 8 characters
                  </span>
                </Typography>
                {/* <Typography color={passwordsMatch ? "green" : "grey"}>
                                    <span style={{ display: "flex", alignItems: "center" }}>
                                        {passwordsMatch && (
                                            <FaCheckCircle
                                                style={{ color: "green", marginRight: "0.5rem" }}
                                            />
                                        )}
                                        Passwords must match
                                    </span>
                                </Typography> */}
              </div>
            }
            arrow
          >
            <TextField
              className="form_input w-full bg-white bg-opacity-[0.05] rounded-lg text-white"
              type={showPassword ? "text" : "password"}
              name="newPassword"
              id="new-password-input"
              value={newPassword}
              onChange={handlePasswordChange}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword ? (
                      <FaEye
                        fontSize={22}
                        cursor={"pointer"}
                        color="white"
                        onClick={toggleShowPassword}
                      />
                    ) : (
                      <FaEyeSlash
                        fontSize={22}
                        cursor={"pointer"}
                        color="white"
                        onClick={toggleShowPassword}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </CustomTooltip>
        </div>

        <div className="space-y-4 mt-6">
          <label
            className="text-lg font-medium text-white"
            htmlFor="confirm-password-input"
          >
            Confirm Password
          </label>
          <CustomTooltip
            title={
              <div>
                <Typography color={"black"} marginBottom={1}>
                  Password must contain the following:
                </Typography>

                <Typography color={passwordsMatch ? "green" : "grey"}>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {passwordsMatch && (
                      <FaCheckCircle
                        style={{ color: "green", marginRight: "0.5rem" }}
                      />
                    )}
                    Passwords must match
                  </span>
                </Typography>
              </div>
            }
            arrow
          >
            <TextField
              className="form_input w-full bg-white bg-opacity-[0.05] rounded-lg text-white"
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              id="confirm-password-input"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showConfirmPassword ? (
                      <FaEye
                        fontSize={22}
                        cursor={"pointer"}
                        color="white"
                        onClick={toggleShowConfirmPassword}
                      />
                    ) : (
                      <FaEyeSlash
                        fontSize={22}
                        cursor={"pointer"}
                        color="white"
                        onClick={toggleShowConfirmPassword}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </CustomTooltip>
        </div>
      </div>
      <div>
        <button
          onClick={handleSubmit}
          disabled={isLoading}
          className="gradient_button py-2 px-2 w-full hover:bg-opacity-90 italic disabled:opacity-50"
        >
          {isLoading ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
}

export default ResetPassword;
