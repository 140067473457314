import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
    UploadIcon, libraryHeadCells
} from '../../../Raw/raw'
import CommonTable from '../CommonTable/CommonTable'
import { useFetchListLibraryMutation, useFetchSearchLibraryMutation, useRemoveLibraryMutation } from '../../../store/api/LibraryApi'
import AddFileModal from './AddFileModal'
import SearchToggleInput from '../SearchToggleInput'
import { useSnackbar } from '../../../utils/Provider/SnackbarContext'

function Library() {
    const [modalOpen, setModalOpen] = useState(false);
    const [userData, setUserData] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [nodata, setNodata] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [fetchList, { isLoading: isFetching }] = useFetchListLibraryMutation();
    const [fetchSearchList, { isLoading: isFetchingSearch }] = useFetchSearchLibraryMutation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [refreshData, setRefreshData] = useState(false)
    const [open, setOpen] = useState(false);
    const [userIdToRemove, setUserIdToRemove] = useState(null);
    const [removeLibaryFile] = useRemoveLibraryMutation();
    const { showSnackbar } = useSnackbar();
    useEffect(() => {
        const data = {
            per_page: rowsPerPage,
            page,
            searchQuery
        }
        const fetchFunction = searchQuery ? fetchSearchList : fetchList; // Use fetchList for regular fetching, fetchSearchList for searching
        fetchFunction(data).then((result) => {

            const updatedData = result?.data?.data?.data?.map((obj) => {
                return { ...obj, created_at: FormatDate(obj.created_at) }

            })
            if (updatedData?.length === 0) {
                setNodata(true)
            }
            setUserData(updatedData)

            setTotalCount(result?.data?.data?.total)
        }).catch((error) => {
            console.log(error)
        })


        // eslint-disable-next-line
    }, [page, rowsPerPage, searchQuery, refreshData]);

    const FormatDate = (timestamp) => {
        const date = new Date(timestamp);
        const month = date.getMonth() + 1; // Month starts from 0, so add 1
        const day = date.getDate();
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const period = hours >= 12 ? 'PM' : 'AM'; // Determine AM or PM

        // Format hours to 12-hour format
        const formattedHours = hours % 12 || 12;

        // Format minutes to two digits with leading zero if necessary
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

        const formattedDate = `${month}/${day}/${year}, ${formattedHours}:${formattedMinutes} ${period}`;

        return formattedDate;
    }

    const [selectedFile, setSelectedFile] = useState(null);
    const handleCancel = () => {
        setModalOpen(false);
        setSelectedFile(null)
    };
    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleOpenDialog = (id) => {
        setUserIdToRemove(id);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setUserIdToRemove(null);
    };


    const handleRemoveUser = () => {
        removeLibaryFile(userIdToRemove).then((result) => {
            console.log(result)
            if (result?.data?.status) {
                showSnackbar('success', result?.data?.message);
                setRefreshData((prev) => !prev)
            }
            else if(result?.error?.status===500){
                showSnackbar('error', 'Internal Server Error');
            }
            else if(result?.error?.status===422){
                showSnackbar('error', result?.error?.data?.message);
            }
            else {
                if(result?.data){
                    
                    showSnackbar('error', result?.data?.message);
                }
            }
          
        }).catch((error) => {
            console.log(error)
        });
        handleCloseDialog();
    };
    return (
        <>
            <div className='flex justify-between'>
                <h2 className='text-[32px] font-[700] uppercase'>Library</h2>

                <div className='flex gap-x-4 h-12'>
                    <div>
                        <Button component="label" onClick={handleModalOpen} className='flex !min-w-[180px] gap-x-[12px] !px-[24px] !py-[8px] ' variant='pinkpurplegradient' >
                            <UploadIcon />
                            Add New
                        </Button>
                    </div>

                    <SearchToggleInput
                        setSearchQuery={setSearchQuery}
                    />
                </div>
            </div>

            <CommonTable
                data={userData} totalCount={totalCount}
                page={page} setPage={setPage}
                rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage}
                handleRemove={handleOpenDialog}
                headCells={libraryHeadCells} isLoading={isFetching || isFetchingSearch}
                nodata={nodata}
                tableName={"Library"}
            />
            <AddFileModal
                open={modalOpen}
                onClose={handleCancel}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                setRefreshData={setRefreshData}
            />

            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    Are you sure you want to remove this file?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleRemoveUser}>Remove</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Library