import "./App.css";
import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./routes/MainRoutes";
import { ThemeProvider, createTheme } from "@mui/material";
import { SnackbarProvider } from "./utils/Provider/SnackbarContext";
import GlobalSnackbar from "./utils/Provider/GlobalSnackbar";
function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#E4146E", // Change the primary color
      },
      text: {
        primary: "#fff", // Set primary text color to #fff
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#fff", // Set the text color of the InputLabel to #fff
            fontWeight: "700",
            fontSize: "20px",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            background: "#2d2d2d",
            "&.Mui-selected": {
              backgroundColor: "#e4146e1f", // Change this to your desired color
              color: "#fff", // Change this to your desired text color
            },
          },
        },
      },

      MuiPaper: {
        styleOverrides: {
          root: {
            background: "#2d2d2d",
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#E4146E", // Change the color of the checkbox
            padding: "0",
            marginRight: "12px",
            "&.Mui-checked": {
              color: "#E4146E", // Change the color of the checkbox when checked
              "&:hover": {
                backgroundColor: "transparent", // Remove the background color on hover
              },
            },
            "&:hover": {
              backgroundColor: "transparent", // Remove the background color on hover
            },
            "&.Mui-disabled": {
              color:'#E4146E', 
              opacity:'0.5'
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            margin: "0",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: "#fff",
            textTransform: "uppercase",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: 1.5,
            padding: "12px 24px",
            "@media (max-width: 991px)": {
              // Adjust styles for small screens
              fontSize: "12px",
              padding: "8px 12px",
              minWidth: "50px",
            },
            "&.Mui-selected": {
              backgroundColor: "#E4146E !important", // Change this to your desired color
              color: "#fff", // Change this to your desired text color
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            background: "#2d2d2d",
            borderRadius: "8px",
            "&:hover": {
              borderColor: "#E4146E",
              "& $notchedOutline": {
                borderColor: "#E4146E",
              },
            },
          },
          icon: {
            color: "#E4146E", // Change icon color here
            fontSize: "2rem",
          },
        },
      },
      MuiOutlinedInput:{
        styleOverrides: {
          root: {
            background: "#2d2d2d",
            borderRadius: "8px",
            "&:hover": {
              borderColor: "#E4146E",
              "& $notchedOutline": {
                borderColor: "#E4146E",
              },
            },
          },
          icon: {
            color: "#E4146E", // Change icon color here
            fontSize: "2rem",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            width: "100%",
            fontWeight: "700",
            fontSize: "18px",
            borderRadius: "8px",
          },
          contained: {
            '&:disabled': {
              background:'#E4146E',
              color:'#fff',
              opacity: 0.5, 
            },
          },
        },
        variants: [
          {
            props: { variant: "gradient" },
            style: {
              background: "linear-gradient(90deg, #78BE20 0%, #0C9DD9 100%)",
              color: "white", // Set text color to white
            },
          },
          {
            props: { variant: "pinkpurplegradient" },
            style: {
              background: "linear-gradient(90deg, #E4077D 0%, #6226A7 100%)",
              color: "white", // Set text color to white
            },
          },
        ],
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: "24px",
            background:
              "radial-gradient(129.62% 164.52% at 106.15% 5.08%, rgba(0, 0, 0, 0.08) 11.75%, rgba(98, 38, 167, 0.10) 44.3%, rgba(228, 7, 125, 0.10) 86.9%)",
            backdropFilter: "blur(40px)",
            padding: "24px",
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: "linear-gradient(90deg, #78BE20 0%, #0C9DD9 100%)",
            borderRadius: "10px",
          },
        },
      },

      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom:0,
       
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <BrowserRouter>
          <MainRoutes />
          <GlobalSnackbar />
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
