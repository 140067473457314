import React from 'react';
import { useSnackbar } from '../../utils/Provider/SnackbarContext';

function FileUploader({selectedFile, setSelectedFile}) {
  const { showSnackbar } = useSnackbar();
 
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (validateFileType(file)) {
      setSelectedFile(file);
      handleFile(file);
    } else {
      showSnackbar('error',' Only CSV and XLS files are supported.');
    }
  };
  const handleFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (validateFileType(file)) {
      setSelectedFile(file);
      handleFile(file);
    } else {
      showSnackbar('error',' Only CSV and XLS files are supported.');
    }
  };

  const handleFile = (file) => {
    // Handle the file upload logic here
    console.log('Uploaded file:', file);
  };

  const preventDefaultAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const validateFileType = (file) => {
    return (
      file.type === 'text/csv' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel'
    );
  };
  return (
    <label
    htmlFor="file-upload"
      className='bg-[#363636] text-center text-lg h-52 rounded-xl px-2 border-[1px] border-[#5f5f5f] border-dashed min-h-40 w-full flex-1 flex items-center justify-center cursor-pointer'
      onDrop={handleFileDrop}
      onDragOver={preventDefaultAction}
      onDragEnter={preventDefaultAction}
    >
      <div>
        <input
          type="file"
          id="file-upload"
          className="hidden"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={handleFileUpload}
        />
        {selectedFile ? (
          <div>
            <div className='font-bold break-all'>{selectedFile.name}</div>
            <div className='text-[#9D9D9D] font-normal text-[14px] break-all'>{selectedFile.type}</div>
          </div>
        ) : (
          <div>
            <div className='font-bold'><span className='gradient_red_orange_text'>Click to add</span> or <br/> drop file</div>
            <div className='text-[#9D9D9D] font-normal text-[14px]'>CSV and XLS file supported</div>
          </div>
        )}
      </div>
    </label>
  );
}

export default FileUploader;
