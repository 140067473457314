import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {
   CrossIcon, PlusIcon } from '../../../Raw/raw';
import SelectCheckBoxDropdown from '../CheckBoxDropdown';
import { useFetchListQuery } from '../../../store/api/PermissionApi'
import { useCreateRoleMutation, useUpdateRoleMutation } from '../../../store/api/RoleApi';
import { useSnackbar } from '../../../utils/Provider/SnackbarContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 472,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: "24px",
  p: 4,
  background: "radial-gradient(129.62% 164.52% at 106.15% 5.08%, rgba(0, 0, 0, 0.08) 11.75%, rgba(98, 38, 167, 0.10) 44.3%, rgba(228, 7, 125, 0.10) 86.9%)",
  backdropFilter: "blur(40px)"
};

export default function AddRoleModal({permissionName,setPermissionName,userName, setUserName,open, setOpen,roleId,setRoleId,setRefreshData}) {
  const [createRole,{isLoading,}] = useCreateRoleMutation();
  const [updateRole,{isLoading:isUpdating,}] = useUpdateRoleMutation();
  const handleOpen = () => {
    setPermissionName([])
    setUserName('')
    setRoleId(null)
    setOpen(true);
  }
  const handleClose = () => setOpen(false);
  const { data: fetchList } = useFetchListQuery();
  const { showSnackbar } = useSnackbar();




  const handleSave = () => {
    if(roleId){
      updateRole({id:roleId, name: userName, permission: permissionName.includes('Select All') ? fetchList.data.map((obj) => obj.name) : permissionName }).then((result)=>{
        if(result?.data?.status){
          setOpen(false)
          showSnackbar('success',result?.data?.message)
          setRefreshData(prev=>!prev)
        }
        else if(result?.error){
          const firstErrorKey = Object.keys(result?.error?.data?.errors)[0];
          const firstErrorMessage = result?.error?.data?.errors[firstErrorKey][0];
          showSnackbar('error', firstErrorMessage)
        }
      }).catch((error)=>{
        console.error('Error submitting form:', error);
        showSnackbar('error', 'An error occurred while processing your request.');
      })
    }
    else{
     
      createRole({ name: userName, permission: permissionName.includes('Select All') ? fetchList.data.map((obj) => obj.name) : permissionName }).then((result)=>{
        if(result?.data?.status){
          setOpen(false)
          showSnackbar('success',result?.data?.message)
          setRefreshData(prev=>!prev)
        }
        else if(result?.error){
          const firstErrorKey = Object.keys(result?.error?.data?.errors)[0];
          const firstErrorMessage = result?.error?.data?.errors[firstErrorKey][0];
          showSnackbar('error', firstErrorMessage)
        }
      })
      .catch((error)=>{
        console.error('Error submitting form:', error);
        showSnackbar('error', 'An error occurred while processing your request.');
      })
    }

  }

  return (
    <div>

      <Button onClick={handleOpen}
        className='flex !min-w-[200px] gap-x-[12px] !px-[24px] !py-[8px] ' variant='pinkpurplegradient' >
        <PlusIcon />     ADD ROLE
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='flex justify-between items-center'><h2 className='text-[32px] font-[700] uppercase'>{roleId?'Edit Role':'Create Role'}</h2>
            <div onClick={() => setOpen(false)}>
              <CrossIcon />

            </div>
          </div>
          <div className='flex flex-col gap-y-[20px]'>

            <div className='flex flex-col gap-y-[16px]'>
              <label className="text-base   font-bold text-white" htmlFor="email-input">
                Name
              </label>
              <input
                className='form_input w-full px-3 py-4 bg-white bg-opacity-[0.05] rounded-lg text-white h-[50px]'
                type="text"
                name="name"
                id="email-input"
                value={userName}
                onChange={
                  (e) => setUserName(e.target.value)

                }
             
                placeholder='John Doe'
                autoComplete="off"
              /></div>
 <label className="text-base   font-bold text-white" htmlFor="email-input">
                Permissions
              </label>

            <SelectCheckBoxDropdown data={fetchList?.data} setPersonName={setPermissionName} personName={permissionName} />


          </div>
          <div className='mt-[22px] flex justify-between gap-4'>
            <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
            <Button variant="gradient"
              onClick={() => {
                handleSave();
              }}
              disabled={isLoading || isUpdating}
            >Save</Button>
          </div>

        </Box>
      </Modal>
    </div>
  );
}