import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// const token=localStorage.getItem("xperi_userToken");
const QaApi = createApi({
  reducerPath: "qaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/api`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      const token = state.users.token
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchQaList: builder.mutation({
        query: (data) => {
          return {
            url: `/qa/response-file-view?page=${data?.page+1}`,
            body:data,
            method: "POST",
          };
        },
      }),
      fetchQaHistory: builder.mutation({
        query: (data) => {
          return {
            url: `/qa/response-history?page=${data?.page+1}`,
            body:data,
            method: "POST",
          };
        },
      }),
      SelectAll: builder.mutation({
        query: (data) => {
          return {
            url: `/qa/response-update-all`,
            body:data,
            method: "POST",
          };
        },
      }),
      fetchQaDropdown: builder.query({
        query: () => {
          return {
            url: "/qa/fileDropDown",
            method: "POST",
          };
        },
      }),
      updateQaRow: builder.mutation({
        query: (data) => {
          return {
            url: "/qa/response-update",
            body:data,
            method: "POST",
          };
        },
      }),
     
    };
  },
});

export const {useFetchQaListMutation,useFetchQaDropdownQuery, useUpdateQaRowMutation, useFetchQaHistoryMutation ,useSelectAllMutation} = QaApi;
export {QaApi}