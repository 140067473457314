import React, { useState } from 'react';
import LoginWrapper from './LoginWrapper';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../../store/thunks/LoginUser';
import { useSnackbar } from '../../../utils/Provider/SnackbarContext';

function Login() {
  const { showSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    // Clear the error message when the user starts typing in the input field
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const errorsCopy = { ...errors };

    // Validate email
    if (!formData.email.trim()) {
      errorsCopy.email = 'Email is required';
      isValid = false;
    }

    // Validate password
    if (!formData.password.trim()) {
      errorsCopy.password = 'Password is required';
      isValid = false;
    }

    setErrors(errorsCopy);
    return isValid;
  };

  const submitData = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      dispatch(loginUser(formData))
        .unwrap()
        .then((result) => {
          if (result.status) {
            localStorage.removeItem('xperi_userToken')
            localStorage.setItem("xperi_userToken", result.token);
            navigate('/dashboard', { replace: true });
            showSnackbar('success',result?.message)
          }
          else{
            showSnackbar('error',result?.message)
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error.message);
          showSnackbar('error',error.message)
        });
    }
  };

  return (
    <LoginWrapper>
      <form onSubmit={submitData} className='space-y-8 xl:space-y-[45px] w-full'>
        <div className='space-y-4'>
          <p className='gradient_text_small'>Hey! You were missed</p>
          <h1 className='text-[32px] font-bold uppercase text-white'>Please Login to continue</h1>
          <p className='text-white text-base font-normal'>Enter your registered email and password</p>
        </div>
        <div>
          <div className='space-y-4'>
            <label className="text-lg font-medium text-white" htmlFor="email-input">
              Email
            </label>
            <input
              className='form_input w-full px-3 py-4 bg-white bg-opacity-[0.05] rounded-lg text-white'
              type="email"
              name="email"
              id="email-input"
              value={formData?.email}
              onChange={handleChange}
              placeholder='Example@email.com'
              autoComplete="off"
            />
            <div className='text-red-500'>{errors.email}</div>
          </div>
          <div className='space-y-4 mt-6'>
            <label className="text-lg font-medium text-white" htmlFor="password-input">
              Password
            </label>
            <input
              className='form_input w-full px-3 py-4 bg-white bg-opacity-[0.05] rounded-lg text-white'
              type="password"
              name="password"
              id="password-input"
              autoComplete="off"
              value={formData?.password}
              onChange={handleChange}
              placeholder='Minimum 8 characters'
            />
            <div className='text-red-500'>{errors.password}</div>
          </div>
          <div className='pt-4 text-white flex justify-between items-center'>
            <FormControlLabel control={<Checkbox />} label="Remember me" />
            <Link to="/forget-password" className='text-primaryPink cursor-pointer'>
              Forgot password?
            </Link>
          </div>
        </div>
        <div>
          <button
            type='submit'
            disabled={isLoading}
            className='gradient_button py-2 px-2 w-full hover:bg-opacity-90 italic disabled:opacity-50'>
            Login
          </button>
        </div>
      </form>
    </LoginWrapper>
  );
}

export default Login;
