import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// const token=localStorage.getItem("xperi_userToken");
const ResetPasswordApi = createApi({
  reducerPath: "resetpassword",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/api`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      const token = state.users.token
      if (token) {
        headers.set("token", token);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      forgetPassword: builder.mutation({
        query: (obj) => {
          return {
            url: "/forgot-password",
            body: obj,
            method: "POST",
          };
        },
      }),
      confirmEmailCode: builder.mutation({
        query: (obj) => {
          return {
            url: "/confirm-email",
            body: obj,
            method: "POST",
          };
        },
      }),
      resetPassword: builder.mutation({
        query: (obj) => {
          return {
            url: "/reset-password",
            body: obj,
            method: "POST",
          };
        },
      }),
    };
  },
});

export const {useForgetPasswordMutation, useConfirmEmailCodeMutation ,useResetPasswordMutation} = ResetPasswordApi;
export {ResetPasswordApi}