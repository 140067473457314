import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function SelectCheckBoxDropdown({data ,setPersonName ,personName   }) {

  // const handleChange = (event) => {
  //   const { target: { value } } = event;
  //   setPersonName(typeof value === 'string' ? value.split(',') : value);
  // }
  // React.useEffect(() => {
  //   console.log(personName)
  // }, [personName])
  
  const handleChange = (event) => {
    const { target: { value } } = event;

    if (value.includes("Select All")) {
      if(personName.length === data.length){
        setPersonName([])
      }
      else{

        setPersonName(data.map(item => item.name));
      }

    } 
    else {
     
      setPersonName(value);
    }
  };
  return (
    <div>
      <FormControl sx={{  width: '100%' }}>
     
        <Select
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput className='!bg-white !bg-opacity-[0.05]' />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >

{/* 
          {[{name:"Select All"},...data]?.map((obj,i) => (
            <MenuItem key={i} value={obj.name}>
              <Checkbox checked={personName?.includes(obj.name) || personName?.includes("Select All")} />
              <ListItemText primary={obj.name} />
            </MenuItem>
          ))} */}
          <MenuItem key="Select All" value="Select All">
            <Checkbox checked={personName?.length === data?.length} />
            <ListItemText primary="Select All" />
          </MenuItem>
          {data?.map((obj, i) => (
            <MenuItem key={i} value={obj?.name}>
              <Checkbox checked={personName?.includes(obj.name)} />
              <ListItemText primary={obj?.name} />
            </MenuItem>
             ))}
        </Select>
      </FormControl>
    </div>
  );
}