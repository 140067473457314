import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const LibraryApi = createApi({
  reducerPath: "libraryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/api`,
    prepareHeaders: (headers, { getState,method }) => {
      const state = getState();
      const token = state.users.token
      if (token) {
        
        headers.set("Authorization", `Bearer ${token}`);
      }
      if (method === 'POST' && headers.get('Content-Type') !== 'multipart/form-data') {
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchListLibrary: builder.mutation({
        query: (data) => {
          return {
            url: `/library/lists?page=${data?.page+1}`,
            body:{per_page:data?.per_page},
            method: "POST",
          };
        },
        providesTags: ['LibraryList'],
      }),
      fetchSearchLibrary: builder.mutation({
        query: (data) => {
          return {
            url: `/library/search?page=${data?.page+1}`,
            body:{
              per_page:data?.per_page,
              keyword:data?.searchQuery
            },
            method: "POST",
          };
        },
        providesTags: ['LibraryList'],
      }),
      RemoveLibrary: builder.mutation({
        query: (id) => {
          return {
            url: "library/delete",
            body:{document_id:id  } , 
            method: "POST",
          };
        },
        invalidatesTags:["LibraryList"]
      }),
      submitFile: builder.mutation({
        query: (formData) => {
          return {
            url: "/library/create",
            body: formData,
            method: "POST",
          };
        },
        invalidatesTags: ['LibraryList'],
      }),
    };
  },
});

export const {useFetchListLibraryMutation,useSubmitFileMutation,useFetchSearchLibraryMutation ,useRemoveLibraryMutation} = LibraryApi;
export {LibraryApi}