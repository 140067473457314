import {
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import React from "react";
import TableRowMenu from "../CommonTable/tableRowMenu";

import { useRemoveRoleMutation } from "../../../store/api/RoleApi";
import { useSnackbar } from "../../../utils/Provider/SnackbarContext";
const UserRoleTable = ({ data, headCells, isLoading, handleEditRole, totalCount, rowsPerPage, setRowsPerPage, page, setPage, nodata }) => {


    const { showSnackbar } = useSnackbar();
    const [removeRole] = useRemoveRoleMutation()

    const handleDelete = (id) => {
        removeRole(id).then((result) => {

            if (result?.data) {
                if (result?.data?.status) {
                    showSnackbar('success', result?.data?.message);

                }

                else {
                    showSnackbar('error', result?.data?.message);
                }
            }
            else {
                showSnackbar('error', result?.error?.data?.message);
            }

        })
            .catch((error) => {
                showSnackbar('error', error?.data?.message)
                console.error('Failed to create user:', error);
            });

    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <div className="mt-[32px]">
            <TableContainer className="w-[100%]">
                <Table>
                    <TableHead className="!rounded-[10px] ">
                        <TableRow>
                            {headCells?.map((cell, i) => {
                                return (
                                    <TableCell
                                        key={'item-' + i}
                                        sx={{ fontSize: 18, fontWeight: 700, whiteSpace: "nowrap", textTransform: 'uppercase' }}
                                        className={`${i === 0 && "!rounded-tl-[10px] !rounded-bl-[10px]"
                                            }  ${i === headCells.length - 1 &&
                                            "!rounded-tr-[10px] !rounded-br-[10px]"
                                            }`}
                                    >
                                        {cell.label}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={headCells?.length}></TableCell>
                        </TableRow>

                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={headCells.length}>
                                    <LinearProgress color="primary" />
                                </TableCell>
                            </TableRow>
                        ) : (
                            data?.data
                                // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                ?.map((row, count) => {
                                    return (
                                        <TableRow
                                            key={'item-' + count}
                                            className={`${count % 2 === 0 && "!bg-[#2D2D2D] !rounded-[10px]"
                                                } `}
                                        >
                                            {headCells?.map((cell, i) => {
                                                return (
                                                    <TableCell
                                                        key={'item-' + i}
                                                        className={`${i === 0 && "!rounded-tl-[10px] !rounded-bl-[10px]"
                                                            } ${i === headCells.length - 1 &&
                                                            "!rounded-tr-[10px] !rounded-br-[10px]"
                                                            }`}
                                                    >
                                                        {cell.id === "edit" ?
                                                            <>
                                                                <TableRowMenu
                                                                    edit={true}
                                                                    handleEditClick={() => handleEditRole(row)}
                                                                    handleRemoveClicked={() => handleDelete(row.id)}
                                                                    id={row?.id}
                                                                />
                                                            </>
                                                            :
                                                            cell.id === "permissions" ?
                                                                <div className="btnColorLayout">
                                                                    {row[cell.id]?.map((obj, index) => {
                                                                        return (<div key={'item-' + index} className="btnColor"
                                                                        >{obj.name}</div>


                                                                        );
                                                                    })}
                                                                </div>


                                                                : row[cell.id]}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })
                        )}
                    </TableBody>
                </Table>
                {
                    nodata && !isLoading && data?.data?.length === 0 &&
                    <div className="text-center">No Data Found</div>
                }
            </TableContainer>
            <TablePagination
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                onPageChange={handleChangePage}
            />
        </div>
    );
};

export default UserRoleTable;
