import React, { useEffect, useState } from "react";
import { statusHeadCells } from "../../../Raw/raw";
import CommonTable from "../CommonTable/CommonTable";
import { LuRefreshCw } from "react-icons/lu";

import {
  useFetchStatusListMutation,
  useFetchStatusSearchMutation,
} from "../../../store/api/StatusApi";
import SearchToggleInput from "../SearchToggleInput";
import EditStatusQA from "./EditStatusQA";

function Status({ setTabName }) {
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [nodata, setNodata] = useState(false);
  const [fetchList, { isLoading: isFetching }] = useFetchStatusListMutation();
  const [fetchSearchList, { isLoading: isFetchingSearch }] =
    useFetchStatusSearchMutation();
  const [refreshData, setRefreshData] = useState(false);
  useEffect(() => {
    const data = {
      per_page: rowsPerPage,
      page,
      searchQuery,
    };
    const fetchFunction = searchQuery ? fetchSearchList : fetchList; // Use fetchList for regular fetching, fetchSearchList for searching

    fetchFunction(data)
      .then((result) => {
        // console.log("🚀 ~ .then ~ result:", result)
        const updatedData = result?.data?.data?.data?.map((item) => {
          return {
            ...item,
            document_name: item?.documents[0].document_name,
            qa_user: item?.qa_user === null?item?.qa_name: item?.qa_user?.name,
          };
        });
        if (updatedData.length === 0) {
          setNodata(true);
        }
        setTableData(updatedData);
        setTotalCount(result?.data?.data?.total);
      })
      .catch((error) => [console.log(error)]);

    // eslint-disable-next-line
  }, [page, rowsPerPage, refreshData, searchQuery]);

  const [isEditQaOpen, setIsEditQaOpen] = useState(false);
  const [qaUserData, setqaUserData] = useState(null);
  const [selectedQAPerson, setSelectedQAPerson] = useState("");

  const handleEditQA = (qa) => {
    setqaUserData(qa);
    setSelectedQAPerson(parseInt(qa?.qa_person) || "");
    // console.log(qa?.qa_person)
    setIsEditQaOpen(true);
  };
  return (
    <>
      <div className="flex justify-between">
        <h2 className="text-[32px] font-[700] uppercase">ADMIN (Status)</h2>

        <div className="flex gap-x-6 h-12 items-center">
          <LuRefreshCw
            className="cursor-pointer"
            onClick={() => {
              setRefreshData(!refreshData);
              setPage(0);
            }}
            fontSize={24}
          />
          <SearchToggleInput setSearchQuery={setSearchQuery} />
        </div>
      </div>

      <CommonTable
        data={tableData}
        totalCount={totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        headCells={statusHeadCells}
        isLoading={isFetching || isFetchingSearch}
        setRefreshData={setRefreshData}
        setTabName={setTabName}
        nodata={nodata}
        handleEditUser={handleEditQA}
        tableName={"Status"}
      />
      <EditStatusQA
        open={isEditQaOpen}
        onClose={() => setIsEditQaOpen(false)}
        qaUserData={qaUserData}
        selectedQAPerson={selectedQAPerson}
        setSelectedQAPerson={setSelectedQAPerson}
        setRefreshData={setRefreshData}
      />
    </>
  );
}

export default Status;
