import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

function LoginRoutes({ children }) {
    const { isSigned } = useSelector((state) => state?.users)
    if (!isSigned) {
        return children
    }
    else {
        return <Navigate to={"/dashboard"} />
    }
}

export default LoginRoutes