import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DeleteIcon, EditIcon, ThreeDots } from '../../../Raw/raw';

export default function TableRowMenu({edit ,handleEditClick, handleRemoveClicked}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // console.log(id)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
     
      <button
        className='w-11 flex justify-center'
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
          <ThreeDots />
        
          
          

      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {
          edit && 
          <MenuItem onClick={()=>{handleEditClick();handleClose()}}><span className='flex gap-3 min-w-24'><EditIcon/>Edit</span></MenuItem>
        }
        <MenuItem onClick={()=>{handleRemoveClicked();handleClose()}}><span className='flex gap-3 min-w-24'><DeleteIcon/>Delete</span></MenuItem>
       
      </Menu>
    </div>
  );
}