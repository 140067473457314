import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { CloseIcon } from '../../assets/customIcons/customIcons';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../store/thunks/LogoutUser';

function LogoutModal({ open, onClose ,  }) {
  const dispatch = useDispatch()
  const handleLogout =()=>{
    dispatch(logoutUser())
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{fontSize:'32px',fontWeight:700, display:'flex', justifyContent:'space-between',padding:0, paddingBottom:'16px' }}>
       Logout
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{color:'#ffff', paddingRight:0,paddingLeft:0}}>
        <DialogContentText sx={{color:'#ffff'}}>
        Are you sure you want to logout?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className='flex gap-4 w-full'>
        <Button onClick={onClose} sx={{fontStyle:'italic'}}  variant='outlined'>
          Cancel
        </Button>
        <Button onClick={handleLogout} sx={{fontStyle:'italic'}} variant='gradient'>
          Logout
        </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default LogoutModal;
