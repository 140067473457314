import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// const token=localStorage.getItem("xperi_userToken");
const PermissionApi = createApi({
  reducerPath: "PermissionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/api`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      const token = state.users.token
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchList: builder.query({
        query: () => {
          return {
            url: "/all/permission",
            body:{},
            method: "POST",
          };
        },

        providesTags:["getpermissions"]
      }),
 
      RemovePermission: builder.mutation({
        query: (id) => {
          return {
            url: "/permission/delete",
            body:{id:id},
            method: "POST",
          };
        },
        invalidatesTags:["getpermissions"]
      }),
      CreatePermission: builder.mutation({
        query: (name) => {
          return {
            url: "/permission/add",
            body:name,
            method: "POST",
          };
        },
        invalidatesTags:["getpermissions"]
      }),
    };
  },
});

export const {useFetchListQuery , useRemovePermissionMutation ,useCreatePermissionMutation} = PermissionApi;
export {PermissionApi}