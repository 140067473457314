import React from 'react'
import { useStatusDownloadMutation } from '../../../store/api/StatusApi';
import { useSnackbar } from '../../../utils/Provider/SnackbarContext';

function DownloadButton({status,response_document_id,output_description}) {
    const [StausDownload,{isLoading}] = useStatusDownloadMutation();
    const { showSnackbar } = useSnackbar();
    const downloadFileUrl = (fileUrl, fileName) => {
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link );
        link.click();
        document.body.removeChild(link );
      };
    const handleDownload = async () => {
        try {
           

            const response = await StausDownload(response_document_id);
         
            if (response?.data) {
                if (response?.data?.status) {
                    const downloadUrl = response?.data?.data; // Get the download URL from the response
                    // Create a hidden link
                    console.log(downloadUrl)
                    
                    downloadUrl?.forEach((file, index) => {
                        setTimeout(() => {
                        //   const fileName = Object.keys(file)[0];
                          const fileUrl = file;
                          downloadFileUrl(fileUrl, 'file-'+index);
                        }, index * 4000); // Adjust delay if necessary
                      });

                }

            }
           
        } catch (error) {
           
            console.error('Error submitting form:', error);
            showSnackbar('error', 'An error occurred while processing your request.');
        }
    };
  return (
    <>
    {status==='Completed' ?
        <button
        onClick={handleDownload}
        disabled={isLoading}
        className='text-center text-base font-normal border-[1px] px-3 py-2 rounded-lg border-primaryGreen'>
           {/* {isLoading ?'Downloading...':'Downloadable'}  */}
           {output_description}
        </button>
        : status==='In QA' || status === 'In Process'?
        <button
        disabled={true}
        className='text-center text-base font-normal border-[1px] px-3 py-2 rounded-lg border-[#ffff00]'>
            {/* Active when Completed */}
            {output_description}
        </button>
        :status==='Pending'?
        <button
        disabled={true}
        className='text-center text-base font-normal border-[1px] px-3 py-2 rounded-lg border-[#0c9dd9]'>
            {/* Active when Completed */}
            {output_description}
        </button>
        : status==='Regenerate' ?
        <button
        disabled={true}
        className='text-center text-base font-normal border-[1px] px-3 py-2 rounded-lg border-primaryPink'>
            {/* QA Not Pass Regenerate */}
            {output_description}
        </button>
        :
        <>
         <button
        disabled={true}
        className='text-center text-base font-normal border-[1px] px-3 py-2 rounded-lg border-primaryPink'>
            {/* QA Not Pass Regenerate */}
            {output_description}
        </button>
        </>
    }
    </>
  )
}

export default DownloadButton