import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { CloseIcon } from '../../../assets/customIcons/customIcons';
import FileUploader from '../FileUploader';
import { useSubmitFileMutation } from '../../../store/api/LibraryApi';
import { useSnackbar } from '../../../utils/Provider/SnackbarContext';


function AddFileModal({ open, onClose, selectedFile, setSelectedFile,setRefreshData }) {

  const [submitFormData, { isLoading }] = useSubmitFileMutation();
  const { showSnackbar } = useSnackbar();
  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      // Add selected file to FormData if it exists
      if (selectedFile) {
        formData.append('file', selectedFile);
      }



      const response = await submitFormData(formData);
      // console.log(response)
      if (response?.data) {
        if (response?.data?.success) {
          showSnackbar('success', response?.data?.message);

          setSelectedFile(null)
          onClose()
          setRefreshData(prev=>!prev)
        }

        else {
          showSnackbar('error', response?.data?.message);
        }
      }
      else {
        showSnackbar('error', response?.error?.data?.message);
      }

    } catch (error) {
      // Handle error response
      console.error('Error submitting form:', error);
      showSnackbar('error', 'An error occurred while processing your request.');
    }
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ fontSize: '32px', fontWeight: 700, display: 'flex', justifyContent: 'space-between', padding: 0, paddingBottom: '16px' }}>
        Add File
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ color: '#ffff', paddingRight: 0, paddingLeft: 0 }}>
        {/* <DialogContentText sx={{color:'#ffff'}}>
        Are you sure you want to logout?
        </DialogContentText> */}
        <FileUploader
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      </DialogContent>
      <DialogActions>
        <div className='flex gap-4 w-full'>
          <Button onClick={onClose} sx={{ fontStyle: 'italic' }} variant='outlined'>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleSubmit} sx={{ fontStyle: 'italic' }} variant='gradient'>
            Submit
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default AddFileModal;
