import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../axios/axios';

const loginUser = createAsyncThunk("user/login", async (data) => {

    const { email, password } = data;
    try {
        const response = await axios.post(`/login`,  { email, password },
            {
                headers: {
                    "Content-Type": "application/json", // Adjust the content type based on your API requirements
                },
            }
        );

        return response.data;
    }
    catch (error) {
       
        if (error.response) {
            const errorMessage = error.response.data.message ;
            throw new Error(errorMessage);
        } 
        else if(error.message){
            throw new Error(error.message);
        }
        else {
            throw new Error("An error occurred while processing your request.");
        }
    }
}
);

export { loginUser };
