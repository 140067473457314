import * as React from 'react';
import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import { useFetchListQuery } from '../../store/api/RoleApi';

export default function BasicSelect({ role, setRole ,fetchRole }) {
   

// console.log(fetchRole);


    const handleChange = (event) => {
        setRole(event.target.value);
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">Roles</InputLabel> */}


                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={role}
                    // label="role"
                    className='!bg-white !bg-opacity-[0.05]'
                    onChange={handleChange}
                >

                    {fetchRole?.data?.map((obj, i) => (
            
                    <MenuItem key={i} value={obj.id}>{obj.name}</MenuItem>


                    ))}

                </Select>


            </FormControl>
           
        </Box>
    );
}
