import { Route, Routes } from "react-router-dom";
import Login from "../components/auth/login/Login";
import ForgetPassword from "../components/auth/login/ForgetPassword";
import MainPage from "../components/MainPage";
import PrivateRoutes from "./PrivateRoutes";
import LoginRoutes from "./LoginRoute";


const MainRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<LoginRoutes><Login /></LoginRoutes>} />
      <Route exact path="/forget-password" element={<ForgetPassword/>} />
      <Route exact path="/dashboard" element={<PrivateRoutes><MainPage/></PrivateRoutes>} />
      <Route exact path="*" element={<LoginRoutes><Login /></LoginRoutes>} />
      
    </Routes>
  );
};

export default MainRoutes;
