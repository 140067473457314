import { createSlice } from "@reduxjs/toolkit";
import { loginUser } from "../thunks/LoginUser";
import { logoutUser } from "../thunks/LogoutUser";




let newInitialze = {
isSigned: false,
data: null,
token: null,
permissions:[],
isError: null,
}

const userSlice = createSlice({
  name: "user",
  initialState: newInitialze ,
  reducers: {
    // signedInUser(state, action) {
    //   // state.isSigned = true;
    // },
    // signOutUser(state,action) {
      
    //   return newInitialze;
    // }
  },
  extraReducers(builder) {
    builder.addCase(loginUser.pending, (state, action) => {
      // state.isLoading = true;
      console.log("pending");
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      // state.isLoading = false;
      
      state.token = action.payload.token;
      state.data = action.payload.data;
      state.permissions=   action.payload.data?.roles[0].permissions.map(
        (item) => item.name
      );
      if (action.payload.status === false) {
        state.isError = action.payload.message;
      }
       else {
        state.isError = null;
        localStorage.setItem("xperi_userToken", action.payload.token);
        state.isSigned = true
        // window.location.reload();
     
      }
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      // state.isLoading = false;
      // state.error = action.error;
      console.log("error");
    });


    builder.addCase(logoutUser.pending, (state, action) => {
      // state.isLoading = true;
      console.log("pending");
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      // state.isLoading = false;
      
      localStorage.clear();
      return newInitialze
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      // state.isLoading = false;
      // state.error = action.error;
      localStorage.clear();
      window.location.reload();
      console.log("error");
    });


  },
});

// export const { signedInUser,signOutUser } = userSlice.actions;
export default userSlice.reducer;
